import { useQuery } from 'react-query';
import {
  DataCatalogObjectDetails,
  getDetailsByObjectName,
} from '../../../../../../DataCatalog/DataCatalogDetails/DataCatalogDetailsService';

const defaultObjectDetails: DataCatalogObjectDetails = {
  branchName: '',
  collaborationStatus: {
    canOpenIssue: false,
    isFollowing: false,
  },
  dataSourceName: '',
  ds: [],
  ds_location: '',
  fullObjectName: '',
  object_owners_struct: [],
  fullyQualifiedName: '',
  language: '',
  modified_date: '',
  num_identities: 0,
  objectName: '',
  owner: '',
  reported_date: '',
  reporter: '',
  scanDate: 0,
  scannerType: '',
  scanner_type_group: '',
  sizeInBytes: 0,
  type: '',
  update_date: '',
  open_access: '',
};

export const useGetObjectDetails = (fullyQualifiedName: string) => {
  return useQuery(
    ['getObjectDetails', fullyQualifiedName],
    async () => {
      try {
        return getDetailsByObjectName(fullyQualifiedName);
      } catch ({ message }) {
        console.error(`An error has occurred: ${message}`);
        return {
          data: defaultObjectDetails,
        };
      }
    },
    {
      placeholderData: {
        data: defaultObjectDetails,
      },
    },
  );
};
