import { useEffect, useMemo, useState } from 'react';
import { getEventEmitter } from './toolbarEvents';
import { uniqueId } from 'lodash';
import type { BigidAdvancedToolbarFilterUnion, AdvancedToolbarOverrideValue } from '@bigid-ui/components';

export enum DashboardToolbarEvent {
  UPDATE_FILTERS = 'updateFilters',
  CLEAR_FILTERS = 'clearFilters',
  UPDATE_EXTERNAL_FILTERS = 'updateExternalFilters',
}

const emitter = getEventEmitter();

export const useDashboardToolbar = (filters?: BigidAdvancedToolbarFilterUnion[], id?: string) => {
  const [toolbarId] = useState(id ?? uniqueId());
  const [externalAppliedFilters, setExternalAppliedFilters] = useState<AdvancedToolbarOverrideValue[]>([]);
  const [activeFilters, setActiveFilters] = useState<BigidAdvancedToolbarFilterUnion[]>([]);

  const handleFilterUpdate = (filters: BigidAdvancedToolbarFilterUnion[]) => {
    setActiveFilters(filters);
  };

  const updateExternalFilters = (filters: AdvancedToolbarOverrideValue[]) => {
    // Info - Achieve the expected behavior that click on the selected tab will always force the filter update
    setExternalAppliedFilters([...filters]);
  };

  const actions = useMemo(
    () => ({
      updateFilters: (filters: BigidAdvancedToolbarFilterUnion[]) =>
        emitter.emit(DashboardToolbarEvent.UPDATE_FILTERS, { filters, entityId: toolbarId }),
      updateExternalFilters: (filters: AdvancedToolbarOverrideValue[]) =>
        emitter.emit(DashboardToolbarEvent.UPDATE_EXTERNAL_FILTERS, { filters, entityId: toolbarId }),
      clearFilters: () =>
        emitter.emit(DashboardToolbarEvent.CLEAR_FILTERS, {
          entityId: toolbarId,
        }),
    }),
    [toolbarId],
  );

  useEffect(() => {
    const unregisterActions = [
      emitter.addEventListener(
        DashboardToolbarEvent.UPDATE_FILTERS,
        ({ filters, entityId }) => entityId === toolbarId && handleFilterUpdate(filters),
      ),
      emitter.addEventListener(
        DashboardToolbarEvent.UPDATE_EXTERNAL_FILTERS,
        ({ filters, entityId }) => entityId === toolbarId && updateExternalFilters(filters),
      ),
      emitter.addEventListener(
        DashboardToolbarEvent.CLEAR_FILTERS,
        ({ entityId }) => entityId === toolbarId && handleFilterUpdate([]),
      ),
    ];
    return () => unregisterActions.forEach(unregister => unregister());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarId, filters]);

  return { filters, externalAppliedFilters, id: toolbarId, actions, activeFilters };
};
