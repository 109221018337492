import React, { ReactElement } from 'react';
import { BigidNoDataIllustration, BigidDataFrameworksIllustration } from '@bigid-ui/icons';
import { BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { EmptyStateWrapper, UrlWrapper, DescriptionWrapper } from '../WidgetsStyles';
import { generateDataAid } from '@bigid-ui/utils';

interface EmptyStateProps {
  type: EmptyStateType;
  dataAid?: string;
}

type EmptyStateType = 'default' | 'custom';

export const EmptyState = ({ type, dataAid }: EmptyStateProps): ReactElement => {
  const { t } = useLocalTranslation('compliance.emptyState');

  const handleRedirect = () => {
    $state.go(CONFIG.states.FRAMEWORKS);
  };

  switch (true) {
    case type === 'custom':
      return (
        <EmptyStateWrapper data-aid={dataAid}>
          <BigidDataFrameworksIllustration size="small" />
          <DescriptionWrapper>
            <BigidBody1>{t('compoundText.part1')}</BigidBody1>
            <UrlWrapper data-aid={generateDataAid(dataAid, ['frameworksLink'])} onClick={handleRedirect}>
              {t('compoundText.part2')}
            </UrlWrapper>
            <BigidBody1>{t('compoundText.part3')}</BigidBody1>
          </DescriptionWrapper>
        </EmptyStateWrapper>
      );
    default:
      return (
        <EmptyStateWrapper data-aid={dataAid}>
          <BigidNoDataIllustration size="small" />
          <DescriptionWrapper>
            <BigidBody1>{t('defaultText')}</BigidBody1>
          </DescriptionWrapper>
        </EmptyStateWrapper>
      );
  }
};
