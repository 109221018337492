import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';

type TextDirectionType = 'row' | 'column';

export const Root = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  overflow: hidden;
  padding: 0px 0px 10px 8px;
  position: relative;
`;

export const Main = styled('div')<{ widgetHeigth?: string }>(({ widgetHeigth = 'auto' }) => ({
  width: '100%',
  padding: '24px',
  height: widgetHeigth,
}));

export const Content = styled('div')<{ excludedContentHeight?: string }>(
  ({ excludedContentHeight = '48px' }) => `
	width: 100%;
  height: calc(100% - ${excludedContentHeight});
  display: flex;
  justify-content: center;
  align-items: center;
`,
);

export const TitleWrapper = styled('div')<{ textDirection?: TextDirectionType }>(
  ({ textDirection = 'row' }) => `
	display: flex;
  width: 100%;
  flex-direction: ${textDirection};
	justify-content: space-between;
	row-gap: 6px;
`,
);

export const EmptyStateWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 8px;
`;

export const DescriptionWrapper = styled('div')`
  display: flex;
  column-gap: 4px;
`;

export const UrlWrapper = styled(BigidBody1)`
  color: blue;
  cursor: pointer;
`;

export const WidgetWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.bigid.gray200}`,
  boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: '6px',
}));

export const WidgetsSection = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`;

export const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
