import { cloneDeep } from 'lodash';
import {
  BigidAccessIntelligenceIcon,
  BigidActionCenterIcon,
  BigidActivityHighlightsIcon,
  BigidAdministrationIcon,
  BigidAdvanceSearchIcon,
  BigidApplicationSetupIcon,
  BigidApplicationsIcon,
  BigidCatalogIcon,
  BigidCertificatesIcon,
  BigidCheckedViewIcon,
  BigidClassificationIcon,
  BigidClassifiersIcon,
  BigidClusterAnalysisIcon,
  BigidCompoundAttributesIcon,
  BigidCorrelationSetIcon,
  BigidCorrelationsIcon,
  BigidCredentialsIcon,
  BigidDashboardIcon,
  BigidDashboardManagerIcon,
  BigidDataCustodianIcon,
  BigidDataDeletionIcon,
  BigidDataExplorerIllustration,
  BigidDataInsightsStudioIcon,
  BigidDataManagementIllustration,
  BigidDataPiplineIcon,
  BigidDataRightsFulfillmentIcon,
  BigidDataSearchIcon,
  BigidDataSourceOnlyIcon,
  BigidDiscoveryIcon,
  BigidExecutiveReportIcon,
  BigidFavoritesIllustration,
  BigidFileLabelingIcon,
  BigidFlameIcon,
  BigidFlowIcon,
  BigidGroupIcon,
  BigidHistoryIcon,
  BigidHotspotIcon,
  BigidInventoryIcon,
  BigidLabelingReportIcon,
  BigidMessageIcon,
  BigidPoliciesIcon,
  BigidPrivacyPortalIcon,
  BigidProcessIcon,
  BigidProxyIcon,
  BigidReportCenterIcon,
  BigidReportRiskIcon,
  BigidReportsIllustration,
  BigidSavedQueriesIcon,
  BigidScannedFilesIcon,
  BigidScanResultsDetailsIcon,
  BigidScanResultsSummaryIcon,
  BigidScanWindowIcon,
  BigidSecondarySourcesIcon,
  BigidSensitivityClassificationIcon,
  BigidSettingsIllustration,
  BigidSpaceshipDefaultIcon,
  BigidStarIcon,
  BigidTagIcon,
  BigidUsageReportIcon,
  BigidVaultIcon,
  BigidVendorIcon,
  BigidIbanIcon,
  BigidShieldIcon,
  BigidRiskConfigurationIcon,
  BigidFrameworkIcon,
  BigidDataSourceIllustration,
} from '@bigid-ui/icons';
import { CONFIG } from '../../../config/common';
import {
  BigidNavigationItemType,
  inserItemInMenu,
  MenuSettings,
  MenuSettingsItem,
  MenuSettingsItemBase,
} from '@bigid-ui/components';
import { sidebarService } from '../../services/sidebarService';
import { userPreferencesService } from '../../services/userPreferencesService';
import {
  APP_MANAGEMENT_USER_PREFERENCE_NAME,
  ApplicationManagementPreferences,
  CustomApps,
  NavigationMenuItem,
} from '../../views/ApplicationsManagement/applicationManagementService';
import { userPreferencesEventEmitter, UserPreferencesEvents } from '../../services/eventEmitters/userPreferencesEvents';
import { ALL_WORKSPACES, getWorkspacesByNavLink, WorkspaceId } from '../../utilities/workspacesUtils';
import { isPrivacyPortal } from '../../utilities/privacyPortalUtilities';
import { getSidebarItemsFromCustomApp } from '../../utilities/tpaUtilities';
import {
  isGettingStartedEnabled,
  isGettingStartedSecurityEnabled,
  isRiskRegisterEnabled,
  isWorkspacesEnabled,
} from '../../utilities/featureFlagUtils';
import { noop } from 'angular';
import { licenseService } from '../../services/licenseService';
import { isPermitted } from '../../services/userPermissionsService';
import { GETTING_STARTED_PERMISSIONS, RISK_REGISTER_PERMISSIONS } from '@bigid/permissions';

export const PREFERENCES_NAME = 'sideNavigation';
export const SIDE_NAVIGATION_BY_WORKSPACE = 'sideNavigationByWorkspace';

type BigidSidebarMenuItemType = BigidNavigationItemType['quickViewSchema']['menuItems'][0] & {
  order?: number;
};

export type BigidSidebarItemType = BigidNavigationItemType & {
  order?: number;
  workspaces?: WorkspaceId[];
  name?: string;
  quickViewSchema?: BigidNavigationItemType['quickViewSchema'] & {
    menuItems: BigidSidebarMenuItemType[];
  };
};

export const getInitialNavbarItems = (onNavigationItemClicked: (link: string) => void): BigidSidebarItemType[] => {
  if (isWorkspacesEnabled()) return getInitialWorkspaceItems(onNavigationItemClicked);
  return getInitialBaseItems(onNavigationItemClicked);
};

const isShown = (permission: string) => !licenseService.shouldBlockUserWithLicenseExpired() && isPermitted(permission);

export const getInitialBaseItems = (onNavigationItemClicked: (link: string) => void): BigidSidebarItemType[] => [
  {
    isVisible: true,
    icon: BigidDashboardIcon,
    title: 'Data Overview',
    tooltipText: 'Data Overview',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.CATALOG_DISCOVERY,
    selectedItemStates: [CONFIG.states.CATALOG_DISCOVERY],
  },
  {
    isVisible: true,
    icon: BigidFlameIcon,
    title: 'Security Posture',
    tooltipText: 'Security Posture',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.ACTIONABLE_INSIGHTS,
    selectedItemStates: [CONFIG.states.ACTIONABLE_INSIGHTS],
  },
  {
    isVisible: true,
    icon: BigidDiscoveryIcon,
    title: 'Data Explorer',
    tooltipText: 'Data Explorer',
    onItemClicked: onNavigationItemClicked,
    link: 'dataExplorerTEMP',
    selectedItemStates: [
      'dataExplorerTEMP',
      CONFIG.states.INVENTORY,
      CONFIG.states.CORRELATION,
      CONFIG.states.CLUSTERING,
      CONFIG.states.CLASSIFICATION,
      CONFIG.states.CATALOG,
      CONFIG.states.DATA_EXPLORER,
    ],
    isQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      //masterPageLink: 'masterLink', //TODO: Uncomment when master page is added
      emptyStateIcon: BigidDataExplorerIllustration,
      emptyStateText: 'Click edit and turn on your Catalog',
      menuItems: [
        {
          isVisible: true,
          title: 'Data Explorer',
          link: CONFIG.states.DATA_EXPLORER,
          selectedItemStates: [CONFIG.states.DATA_EXPLORER],
          icon: BigidDiscoveryIcon,
        },
        {
          isVisible: true,
          title: 'Catalog',
          link: CONFIG.states.CATALOG,
          icon: BigidCatalogIcon,
          selectedItemStates: [CONFIG.states.CATALOG],
        },
        {
          isVisible: true,
          title: 'Inventory',
          link: CONFIG.states.INVENTORY,
          icon: BigidInventoryIcon,
          selectedItemStates: [CONFIG.states.INVENTORY],
        },
        {
          isVisible: true,
          title: 'Correlation',
          link: CONFIG.states.CORRELATION,
          icon: BigidCorrelationsIcon,
          selectedItemStates: [CONFIG.states.CORRELATION],
        },
        {
          isVisible: true,
          title: 'Cluster Analysis',
          link: CONFIG.states.CLUSTERING,
          icon: BigidClusterAnalysisIcon,
          selectedItemStates: [CONFIG.states.CLUSTERING],
        },
        {
          isVisible: true,
          title: 'Classification',
          link: CONFIG.states.CLASSIFICATION,
          icon: BigidClassificationIcon,
          selectedItemStates: [CONFIG.states.CLASSIFICATION],
        },
      ],
    },
  },
  {
    icon: BigidReportCenterIcon,
    title: 'Reports',
    tooltipText: 'Reports',
    onItemClicked: onNavigationItemClicked,
    link: 'reportsTEMP', //TODO: Replace when reports master page is added
    isQuickView: true,
    selectedItemStates: [
      'reportsTEMP',
      CONFIG.states.EXECUTIVE_REPORT,
      CONFIG.states.DIGEST_REPORT,
      CONFIG.states.SCAN_ANALYSIS,
      CONFIG.states.SCAN_RESULT,
      CONFIG.states.DASHBOARD,
      CONFIG.states.CUSTOM_DASHBOARD,
    ],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink', //TODO: Replace when reports master page is added
      emptyStateIcon: BigidReportsIllustration,
      emptyStateText: 'Click edit and turn on your Reports',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'BigID Dashboard',
          link: CONFIG.states.DASHBOARD,
          icon: BigidDashboardIcon,
          selectedItemStates: [CONFIG.states.DASHBOARD],
        },
        {
          isVisible: true,
          title: 'Dashboard Manager',
          link: CONFIG.states.CUSTOM_DASHBOARD,
          icon: BigidDashboardManagerIcon,
          selectedItemStates: [CONFIG.states.CUSTOM_DASHBOARD],
        },
        {
          isVisible: true,
          title: 'Executive Report',
          link: CONFIG.states.EXECUTIVE_REPORT,
          icon: BigidExecutiveReportIcon,
          selectedItemStates: [CONFIG.states.EXECUTIVE_REPORT],
        },
        {
          isVisible: true,
          title: 'Activity Highlights',
          link: CONFIG.states.DIGEST_REPORT,
          icon: BigidActivityHighlightsIcon,
          selectedItemStates: [CONFIG.states.DIGEST_REPORT],
        },
        {
          isVisible: true,
          title: 'Risk Assessment',
          link: CONFIG.states.RISK_ASSESSMENT,
          icon: BigidReportRiskIcon,
          selectedItemStates: [CONFIG.states.RISK_ASSESSMENT],
        },
        {
          isVisible: true,
          title: 'Scan Results Details',
          link: CONFIG.states.SCAN_ANALYSIS,
          icon: BigidScanResultsDetailsIcon,
          selectedItemStates: [CONFIG.states.SCAN_ANALYSIS],
        },
        {
          isVisible: true,
          title: 'Scan Results Summary',
          link: CONFIG.states.SCAN_RESULT,
          icon: BigidScanResultsSummaryIcon,
          selectedItemStates: [CONFIG.states.SCAN_RESULT],
        },
        {
          isVisible: true,
          title: 'Scanned Files',
          link: 'scannedFilesDOWNLOAD',
          icon: BigidScannedFilesIcon,
          onItemClicked: sidebarService.onGetScannedFilesReport,
        },
        {
          isVisible: true,
          title: 'Data Custodian',
          link: 'dataCustodianDOWNLOAD',
          icon: BigidDataCustodianIcon,
          onItemClicked: sidebarService.onGetDataCustodianReport,
        },
        {
          isVisible: true,
          title: 'Labeling Propagation',
          icon: BigidLabelingReportIcon,
          link: 'labelingPropagationDOWNLOAD',
          onItemClicked: sidebarService.onGetLabelerReport,
        },
        {
          isVisible: true,
          title: 'Usage Report',
          link: 'usageReportDOWNLOAD',
          icon: BigidUsageReportIcon,
          onItemClicked: sidebarService.onGetUsageReport,
        },
        {
          isVisible: true,
          title: 'Hotspots Report',
          link: CONFIG.states.HOTSPOTS_REPORT,
          icon: BigidHotspotIcon,
          selectedItemStates: [CONFIG.states.HOTSPOTS_REPORT],
        },
      ],
    },
  },
  {
    icon: BigidDataSourceOnlyIcon,
    title: 'Data Management',
    tooltipText: 'Data Management',
    onItemClicked: onNavigationItemClicked,
    link: 'dataManagementTEMP', //TODO: Replace when data management master page is added
    isQuickView: true,
    selectedItemStates: [
      'dataManagementTEMP',
      CONFIG.states.DATA_SOURCE_CONNECTIONS,
      CONFIG.states.SCANS_CONTROLLER,
      CONFIG.states.SCANS_NEW,
      CONFIG.states.SCANS,
      CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
      CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
      CONFIG.states.SCANS_SAVED_PROFILES,
      CONFIG.states.EDIT_SCAN_PROFILE,
      CONFIG.states.CREATE_SCAN_PROFILE,
      CONFIG.states.IDENTITY_SOURCE_CONNECTIONS,
      CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION,
      CONFIG.states.SECONDARY_CONNECTIONS,
      CONFIG.states.NEW_SECONDARY_CONNECTION,
      CONFIG.states.APPLICATIONS_SETUP,
      CONFIG.states.CLASSIFIERS,
      CONFIG.states.RULES,
      CONFIG.states.SCAN_WINDOW,
      CONFIG.states.TAGS,
      CONFIG.states.SAVED_QUERIES,
      CONFIG.states.SENSITIVITY_CLASSIFICATION,
      CONFIG.states.AUDIT,
      CONFIG.states.POLICIES,
      CONFIG.states.SCANS_SCAN_TEMPLATES,
      CONFIG.states.SCANS_PLANNED_SCANS,
      CONFIG.states.DATA_SOURCE_INIT,
      CONFIG.states.CREATE_SCAN,
      CONFIG.states.SCAN_TEMPLATE,
      CONFIG.states.AUTO_DISCOVERY,
      CONFIG.states.VENDORS,
      CONFIG.states.RISK_REGISTER,
      CONFIG.states.RISK_LIBRARY,
      CONFIG.states.RISK_CONTROLS,
    ],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink',
      emptyStateIcon: BigidDataManagementIllustration,
      emptyStateText: 'Click edit and turn on your Data Sources and Scans pages',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'Data Sources',
          link: CONFIG.states.DATA_SOURCE_ROUTER,
          icon: BigidDataSourceOnlyIcon,
          selectedItemStates: [
            CONFIG.states.DATA_SOURCE_CONNECTIONS,
            CONFIG.states.EDIT_DATA_SOURCE_CONNECTION,
            CONFIG.states.NEW_DATA_SOURCE_CONNECTION,
            CONFIG.states.DATA_SOURCE_INIT,
            CONFIG.states.DATA_SOURCE_ROUTER,
            CONFIG.states.AUTO_DISCOVERY,
          ],
        },
        {
          isVisible: true,
          title: 'Scans',
          link: CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
          icon: BigidDataSearchIcon,
          selectedItemStates: [
            CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
            CONFIG.states.SCANS_NEW,
            CONFIG.states.SCANS,
            CONFIG.states.SCANS_SCAN_TEMPLATES,
            CONFIG.states.SCANS_PLANNED_SCANS,
            CONFIG.states.CREATE_SCAN,
            CONFIG.states.SCAN_TEMPLATE,
            CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
            CONFIG.states.SCANS_SAVED_PROFILES,
            CONFIG.states.EDIT_SCAN_PROFILE,
            CONFIG.states.CREATE_SCAN_PROFILE,
            CONFIG.states.SCANS_CONTROLLER,
            CONFIG.states.RISK_REGISTER,
            CONFIG.states.RISK_LIBRARY,
            CONFIG.states.RISK_CONTROLS,
          ],
        },
        {
          isVisible: true,
          title: 'Classifier Tuning',
          link: CONFIG.states.CURATION,
          icon: BigidCheckedViewIcon,
          selectedItemStates: [CONFIG.states.CURATION],
        },
        {
          isVisible: true,
          title: 'Correlation Sets',
          link: CONFIG.states.IDENTITY_SOURCE_CONNECTIONS,
          icon: BigidCorrelationSetIcon,
          selectedItemStates: [CONFIG.states.IDENTITY_SOURCE_CONNECTIONS, CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION],
        },
        {
          isVisible: true,
          title: 'Secondary Sources',
          link: CONFIG.states.SECONDARY_CONNECTIONS,
          icon: BigidSecondarySourcesIcon,
          selectedItemStates: [CONFIG.states.SECONDARY_CONNECTIONS, CONFIG.states.NEW_SECONDARY_CONNECTION],
        },
        {
          isVisible: true,
          title: 'Assets',
          link: CONFIG.states.APPLICATIONS_SETUP,
          icon: BigidApplicationSetupIcon,
          selectedItemStates: [CONFIG.states.APPLICATIONS_SETUP],
        },
        {
          isVisible: true,
          title: 'Classifier Management',
          link: CONFIG.states.CLASSIFIERS,
          icon: BigidClassifiersIcon,
          selectedItemStates: [CONFIG.states.CLASSIFIERS, CONFIG.states.CLASSIFIERS_MANAGEMENT],
        },
        {
          isVisible: true,
          title: 'Compound Attributes',
          link: CONFIG.states.RULES,
          icon: BigidCompoundAttributesIcon,
          selectedItemStates: [CONFIG.states.RULES],
        },
        {
          isVisible: true,
          title: 'Scan Windows',
          link: CONFIG.states.SCAN_WINDOW,
          icon: BigidScanWindowIcon,
          selectedItemStates: [CONFIG.states.SCAN_WINDOW],
        },
        {
          isVisible: true,
          title: 'Tags',
          link: CONFIG.states.TAGS,
          icon: BigidTagIcon,
          selectedItemStates: [CONFIG.states.TAGS],
        },
        {
          isVisible: true,
          title: 'Saved Queries',
          link: CONFIG.states.SAVED_QUERIES,
          icon: BigidSavedQueriesIcon,
          selectedItemStates: [CONFIG.states.SAVED_QUERIES],
        },
        {
          isVisible: true,
          title: 'Sensitivity Classification',
          link: CONFIG.states.SENSITIVITY_CLASSIFICATION,
          icon: BigidSensitivityClassificationIcon,
          selectedItemStates: [CONFIG.states.SENSITIVITY_CLASSIFICATION],
        },
        {
          isVisible: true,
          title: 'Policies',
          link: CONFIG.states.POLICIES,
          icon: BigidPoliciesIcon,
          selectedItemStates: [CONFIG.states.POLICIES],
        },
        {
          isVisible: true,
          icon: BigidVendorIcon,
          title: 'Vendors',
          link: CONFIG.states.VENDORS,
          selectedItemStates: [CONFIG.states.VENDORS],
        },
        ...(isShown(RISK_REGISTER_PERMISSIONS.ACCESS.name) && isRiskRegisterEnabled()
          ? [
              {
                isVisible: true,
                icon: BigidFlameIcon,
                title: 'Risk Register',
                link: CONFIG.states.RISK_REGISTER,
                selectedItemStates: [CONFIG.states.RISK_REGISTER],
              },
            ]
          : []),
        {
          isVisible: true,
          icon: BigidFlameIcon,
          title: 'Risk Library',
          link: CONFIG.states.RISK_LIBRARY,
          selectedItemStates: [CONFIG.states.RISK_LIBRARY],
        },
        {
          isVisible: true,
          icon: BigidShieldIcon,
          title: 'Risk Controls',
          link: CONFIG.states.RISK_CONTROLS,
          selectedItemStates: [CONFIG.states.RISK_CONTROLS],
        },
      ],
    },
  },
  {
    icon: BigidAdministrationIcon,
    title: 'Settings',
    tooltipText: 'Settings',
    onItemClicked: onNavigationItemClicked,
    link: 'settingsTEMP', //TODO: Replace when settings master page is added
    selectedItemStates: [
      'settingsTEMP',
      CONFIG.states.GENERAL_SETTINGS,
      CONFIG.states.ADVANCED_TOOLS,
      CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
      CONFIG.states.CERTIFICATES_MANAGEMENT,
      CONFIG.states.PROXIES,
      CONFIG.states.CREDENTIALS,
      CONFIG.states.CREDENTIAL_PROVIDERS,
      CONFIG.states.ACTION_CENTER,
      CONFIG.states.ACTION_CENTER_AUDIT,
      CONFIG.states.ACTION_CENTER_ACTIONS,
      CONFIG.states.ACTION_CENTER_OVERVIEW,
      CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
      CONFIG.states.LEGAL_ENTITIES,
      CONFIG.states.EMAIL_TEMPLATES,
      CONFIG.states.EMAIL_TEMPLATES_BROWSE,
      CONFIG.states.EMAIL_TEMPLATES_BRANDS,
    ],
    isQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink', //TODO: Replace when settings master page is added
      emptyStateIcon: BigidSettingsIllustration,
      emptyStateText: 'Click edit and turn on your Settings',
      menuItems: [
        {
          title: 'General Settings',
          isVisible: true,
          link: CONFIG.states.GENERAL_SETTINGS,
          icon: BigidAdministrationIcon,
          selectedItemStates: [CONFIG.states.GENERAL_SETTINGS],
        },
        {
          title: 'Advanced Tools',
          isVisible: true,
          link: CONFIG.states.ADVANCED_TOOLS,
          icon: BigidAdvanceSearchIcon,
          selectedItemStates: [CONFIG.states.ADVANCED_TOOLS],
        },
        {
          title: 'Access Management',
          isVisible: true,
          link: CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
          icon: BigidGroupIcon,
          selectedItemStates: [CONFIG.states.IDENTITY_ACCESS_MANAGEMENT],
        },
        {
          title: 'Certificates',
          isVisible: true,
          link: CONFIG.states.CERTIFICATES_MANAGEMENT,
          icon: BigidCertificatesIcon,
          selectedItemStates: [CONFIG.states.CERTIFICATES_MANAGEMENT],
        },
        {
          title: 'Credentials',
          isVisible: true,
          link: CONFIG.states.CREDENTIALS,
          icon: BigidCredentialsIcon,
          selectedItemStates: [CONFIG.states.CREDENTIALS],
        },
        {
          title: 'Proxies',
          isVisible: true,
          link: CONFIG.states.PROXIES,
          icon: BigidProxyIcon,
          selectedItemStates: [CONFIG.states.PROXIES],
        },
        {
          title: 'Credential Providers',
          isVisible: true,
          link: CONFIG.states.CREDENTIAL_PROVIDERS,
          icon: BigidVaultIcon,
          selectedItemStates: [CONFIG.states.CREDENTIAL_PROVIDERS],
        },
        {
          title: 'Action Center',
          isVisible: true,
          link: CONFIG.states.ACTION_CENTER_ACTIONS,
          icon: BigidActionCenterIcon,
          selectedItemStates: [
            CONFIG.states.ACTION_CENTER_ACTIONS,
            CONFIG.states.ACTION_CENTER,
            CONFIG.states.ACTION_CENTER_AUDIT,
            CONFIG.states.ACTION_CENTER_OVERVIEW,
            CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
          ],
        },
        {
          isVisible: true,
          title: 'Audit',
          link: CONFIG.states.AUDIT,
          icon: BigidHistoryIcon,
          selectedItemStates: [CONFIG.states.AUDIT],
        },
        {
          isVisible: true,
          title: 'Email Templates',
          link: CONFIG.states.EMAIL_TEMPLATES_BROWSE,
          icon: BigidMessageIcon,
          selectedItemStates: [CONFIG.states.EMAIL_TEMPLATES_BROWSE, CONFIG.states.EMAIL_TEMPLATES_BRANDS],
        },
        {
          isVisible: true,
          title: 'Legal Entities',
          link: CONFIG.states.LEGAL_ENTITIES,
          icon: BigidIbanIcon,
          selectedItemStates: [CONFIG.states.LEGAL_ENTITIES],
        },
      ],
    },
  },
];

export const getInitialWorkspaceItems = (onNavigationItemClicked: (link: string) => void): BigidSidebarItemType[] => [
  {
    order: 100,
    name: 'DATA_OVERVIEW',
    isVisible: true,
    icon: BigidDashboardIcon,
    title: 'Data Overview',
    tooltipText: 'Data Overview',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.CATALOG_DISCOVERY,
    selectedItemStates: [CONFIG.states.CATALOG_DISCOVERY],
    workspaces: [WorkspaceId.DISCOVERY, WorkspaceId.SECURITY],
  },
  {
    order: 200,
    name: 'SECURITY_POSTURE',
    isVisible: true,
    icon: BigidFlameIcon,
    title: 'Security Posture',
    tooltipText: 'Security Posture',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.ACTIONABLE_INSIGHTS,
    selectedItemStates: [
      CONFIG.states.DASHBOARD_COMPLIANCE,
      CONFIG.states.DASHBOARD_SECURITY,
      CONFIG.states.ACTIONABLE_INSIGHTS,
    ],
    isQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      emptyStateIcon: BigidDataExplorerIllustration,
      emptyStateText: 'You have not enough permissions to see it',
      menuItems: [
        {
          isVisible: true,
          title: 'Compliance Dashboard',
          link: CONFIG.states.DASHBOARD_COMPLIANCE,
          selectedItemStates: [CONFIG.states.DASHBOARD_COMPLIANCE],
          icon: BigidDashboardIcon,
          order: 100,
        },
        {
          isVisible: true,
          title: 'Security Dashboard',
          link: CONFIG.states.DASHBOARD_SECURITY,
          selectedItemStates: [CONFIG.states.DASHBOARD_SECURITY],
          icon: BigidDashboardIcon,
          order: 100,
        },
        {
          isVisible: true,
          title: 'Security Posture',
          link: CONFIG.states.ACTIONABLE_INSIGHTS,
          icon: BigidFlameIcon,
          selectedItemStates: [CONFIG.states.ACTIONABLE_INSIGHTS],
          order: 200,
        },
      ],
    },
  },
  {
    order: 200,
    name: 'COMPLIANCE_POSTURE',
    isVisible: true,
    icon: BigidFlameIcon,
    title: 'Compliance Posture',
    tooltipText: 'Compliance Posture',
    onItemClicked:
      isShown(RISK_REGISTER_PERMISSIONS.ACCESS.name) && isRiskRegisterEnabled() ? onNavigationItemClicked : noop,
    link: CONFIG.states.RISK_REGISTER,
    selectedItemStates: [
      CONFIG.states.CATALOG_DISCOVERY,
      CONFIG.states.RISK_REGISTER,
      CONFIG.states.RISK_LIBRARY,
      CONFIG.states.RISK_CONTROLS,
    ],
    workspaces: [WorkspaceId.PRIVACY],
    isQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      emptyStateIcon: BigidDataExplorerIllustration,
      emptyStateText: 'You have not enough permissions to see it',
      menuItems: [
        {
          isVisible: true,
          title: 'Data Overview',
          link: CONFIG.states.CATALOG_DISCOVERY,
          selectedItemStates: [CONFIG.states.CATALOG_DISCOVERY],
          icon: BigidDashboardIcon,
          order: 100,
        },
        ...(isShown(RISK_REGISTER_PERMISSIONS.ACCESS.name) && isRiskRegisterEnabled()
          ? [
              {
                isVisible: true,
                title: 'Risk Register',
                link: CONFIG.states.RISK_REGISTER,
                selectedItemStates: [CONFIG.states.RISK_REGISTER],
                icon: BigidFlameIcon,
                order: 200,
              },
            ]
          : []),
        {
          isVisible: true,
          title: 'Controls Library',
          link: CONFIG.states.RISK_CONTROLS,
          icon: BigidShieldIcon,
          selectedItemStates: [CONFIG.states.RISK_CONTROLS],
          order: 300,
        },
        {
          isVisible: true,
          title: 'Risk Library',
          link: CONFIG.states.RISK_LIBRARY,
          icon: BigidRiskConfigurationIcon,
          selectedItemStates: [CONFIG.states.RISK_LIBRARY],
          order: 400,
        },
      ],
    },
  },
  {
    order: 210,
    name: 'ACCESS_INTELLIGENCE',
    isVisible: true,
    icon: BigidAccessIntelligenceIcon,
    title: 'Access Intelligence',
    tooltipText: 'Access Intelligence',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.ACCESS_GOVERNANCE,
    selectedItemStates: [CONFIG.states.ACCESS_GOVERNANCE],
  },
  {
    order: 230,
    name: 'LABELING',
    isVisible: true,
    icon: BigidFileLabelingIcon,
    title: 'Labeling',
    tooltipText: 'Labeling',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.AZURE_INFORMATION_PROTECTION,
    selectedItemStates: [CONFIG.states.AZURE_INFORMATION_PROTECTION],
  },
  {
    workspaces: [WorkspaceId.PRIVACY],
    name: 'DATA_MAPPING',
    order: 270,
    icon: BigidFlowIcon,
    title: 'Data Mapping',
    tooltipText: 'Data Mapping',
    onItemClicked: onNavigationItemClicked,
    link: 'dataMappingTemp',
    isQuickView: true,
    selectedItemStates: [CONFIG.states.APPLICATIONS_SETUP, CONFIG.states.VENDORS],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      emptyStateIcon: BigidReportsIllustration,
      emptyStateText: 'Install the Data Mapping apps to see your links',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'Legal Entities',
          link: CONFIG.states.LEGAL_ENTITIES,
          icon: BigidIbanIcon,
          selectedItemStates: [CONFIG.states.LEGAL_ENTITIES],
          order: 100,
        },
        {
          isVisible: true,
          title: 'Assets',
          link: CONFIG.states.APPLICATIONS_SETUP,
          icon: BigidApplicationSetupIcon,
          selectedItemStates: [CONFIG.states.APPLICATIONS_SETUP],
          order: 200,
        },
        {
          isVisible: true,
          icon: BigidVendorIcon,
          title: 'Vendors',
          link: CONFIG.states.VENDORS,
          selectedItemStates: [CONFIG.states.VENDORS],
          order: 300,
        },
      ],
    },
  },
  {
    workspaces: [WorkspaceId.PRIVACY],
    name: 'DATA_LIFECYCLE',
    order: 280,
    icon: BigidProcessIcon,
    title: 'Data Lifecycle',
    tooltipText: 'Data Lifecycle',
    onItemClicked: onNavigationItemClicked,
    link: 'dataLifecycleTemp',
    isQuickView: true,
    selectedItemStates: [CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES, CONFIG.states.DATA_MINIMIZATION_REQUESTS],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      emptyStateIcon: BigidReportsIllustration,
      emptyStateText: 'Install the Data Lifecycle apps to see your links',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'Deletion',
          link: CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES,
          icon: BigidDataDeletionIcon,
          selectedItemStates: [CONFIG.states.DATA_MINIMIZATION_DATA_SOURCES, CONFIG.states.DATA_MINIMIZATION_REQUESTS],
          order: 300,
        },
      ],
    },
  },
  {
    workspaces: [WorkspaceId.PRIVACY],
    name: 'DSR_REQUESTS',
    order: 290,
    icon: BigidPrivacyPortalIcon,
    title: 'Privacy Requests',
    tooltipText: 'Privacy Requests',
    onItemClicked: onNavigationItemClicked,
    link: 'dsrRequestsTemp',
    isQuickView: true,
    selectedItemStates: [CONFIG.states.ACCESS_REQUEST_MANAGEMENT],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      emptyStateIcon: BigidReportsIllustration,
      emptyStateText: 'Install the Privacy Portal to see your links',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'Data Rights Fulfillment',
          link: CONFIG.states.ACCESS_REQUEST_MANAGEMENT,
          icon: BigidDataRightsFulfillmentIcon,
          selectedItemStates: [CONFIG.states.ACCESS_REQUEST_MANAGEMENT],
          order: 350,
        },
      ],
    },
  },
  {
    order: 300,
    name: 'DATA_EXPLORER',
    isVisible: true,
    icon: BigidDiscoveryIcon,
    title: 'Data Explorer',
    tooltipText: 'Data Explorer',
    onItemClicked: onNavigationItemClicked,
    link: 'dataExplorerTEMP',
    selectedItemStates: [
      'dataExplorerTEMP',
      CONFIG.states.INVENTORY,
      CONFIG.states.CORRELATION,
      CONFIG.states.CLUSTERING,
      CONFIG.states.CLASSIFICATION,
      CONFIG.states.CATALOG,
      CONFIG.states.DATA_EXPLORER,
    ],
    isQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      //masterPageLink: 'masterLink', //TODO: Uncomment when master page is added
      emptyStateIcon: BigidDataExplorerIllustration,
      emptyStateText: 'Click edit and turn on your Catalog',
      menuItems: [
        {
          isVisible: true,
          title: 'Data Explorer',
          link: CONFIG.states.DATA_EXPLORER,
          selectedItemStates: [CONFIG.states.DATA_EXPLORER],
          icon: BigidDiscoveryIcon,
          order: 100,
        },
        {
          isVisible: true,
          title: 'Catalog',
          link: CONFIG.states.CATALOG,
          icon: BigidCatalogIcon,
          selectedItemStates: [CONFIG.states.CATALOG],
          order: 200,
        },
        {
          isVisible: true,
          title: 'Inventory',
          link: CONFIG.states.INVENTORY,
          icon: BigidInventoryIcon,
          selectedItemStates: [CONFIG.states.INVENTORY],
          order: 300,
        },
        {
          isVisible: true,
          title: 'Classification',
          link: CONFIG.states.CLASSIFICATION,
          icon: BigidClassificationIcon,
          selectedItemStates: [CONFIG.states.CLASSIFICATION],
          order: 400,
        },
        {
          isVisible: true,
          title: 'Correlation',
          link: CONFIG.states.CORRELATION,
          icon: BigidCorrelationsIcon,
          selectedItemStates: [CONFIG.states.CORRELATION],
          order: 500,
        },
        {
          isVisible: true,
          title: 'Cluster Analysis',
          link: CONFIG.states.CLUSTERING,
          icon: BigidClusterAnalysisIcon,
          selectedItemStates: [CONFIG.states.CLUSTERING],
          order: 600,
        },
      ],
    },
  },
  {
    order: 400,
    name: 'REPORTS',
    icon: BigidReportCenterIcon,
    title: 'Reports',
    tooltipText: 'Reports',
    onItemClicked: onNavigationItemClicked,
    link: 'reportsTEMP', //TODO: Replace when reports master page is added
    isQuickView: true,
    selectedItemStates: [
      'reportsTEMP',
      CONFIG.states.DATA_PIPELINE,
      CONFIG.states.EXECUTIVE_REPORT,
      CONFIG.states.DIGEST_REPORT,
      CONFIG.states.SCAN_ANALYSIS,
      CONFIG.states.SCAN_RESULT,
      CONFIG.states.DASHBOARD,
      CONFIG.states.CUSTOM_DASHBOARD,
      CONFIG.states.DATA_INSIGHTS_STUDIO,
      CONFIG.states.RISK_ASSESSMENT,
    ],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink', //TODO: Replace when reports master page is added
      emptyStateIcon: BigidReportsIllustration,
      emptyStateText: 'Click edit and turn on your Reports',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'BigID Dashboard',
          link: CONFIG.states.DASHBOARD,
          icon: BigidDashboardIcon,
          selectedItemStates: [CONFIG.states.DASHBOARD],
          order: 100,
        },
        {
          isVisible: true,
          title: 'Data Pipeline',
          link: CONFIG.states.DATA_PIPELINE,
          icon: BigidDataPiplineIcon,
          selectedItemStates: [CONFIG.states.DATA_PIPELINE],
          order: 200,
        },
        {
          isVisible: true,
          title: 'Data Insights Studio',
          link: CONFIG.states.DATA_INSIGHTS_STUDIO,
          icon: BigidDataInsightsStudioIcon,
          selectedItemStates: [CONFIG.states.DATA_INSIGHTS_STUDIO],
          order: 300,
        },
        {
          isVisible: true,
          title: 'Dashboard Manager',
          link: CONFIG.states.CUSTOM_DASHBOARD,
          icon: BigidDashboardManagerIcon,
          selectedItemStates: [CONFIG.states.CUSTOM_DASHBOARD],
          order: 400,
        },
        {
          isVisible: true,
          title: 'Executive Report',
          link: CONFIG.states.EXECUTIVE_REPORT,
          icon: BigidExecutiveReportIcon,
          selectedItemStates: [CONFIG.states.EXECUTIVE_REPORT],
          order: 500,
        },
        {
          isVisible: true,
          title: 'Activity Highlights',
          link: CONFIG.states.DIGEST_REPORT,
          icon: BigidActivityHighlightsIcon,
          selectedItemStates: [CONFIG.states.DIGEST_REPORT],
          order: 600,
        },
        {
          isVisible: true,
          title: 'Labeling Propagation',
          icon: BigidLabelingReportIcon,
          link: 'labelingPropagationDOWNLOAD',
          onItemClicked: sidebarService.onGetLabelerReport,
          order: 600,
        },
        {
          isVisible: true,
          title: 'Risk Assessment',
          link: CONFIG.states.RISK_ASSESSMENT,
          icon: BigidReportRiskIcon,
          selectedItemStates: [CONFIG.states.RISK_ASSESSMENT],
          order: 700,
        },
        {
          isVisible: true,
          title: 'Scan Results Details',
          link: CONFIG.states.SCAN_ANALYSIS,
          icon: BigidScanResultsDetailsIcon,
          selectedItemStates: [CONFIG.states.SCAN_ANALYSIS],
          order: 800,
        },
        {
          isVisible: true,
          title: 'Scan Results Summary',
          link: CONFIG.states.SCAN_RESULT,
          icon: BigidScanResultsSummaryIcon,
          selectedItemStates: [CONFIG.states.SCAN_RESULT],
          order: 900,
        },
        {
          isVisible: true,
          title: 'Scanned Files',
          link: 'scannedFilesDOWNLOAD',
          icon: BigidScannedFilesIcon,
          onItemClicked: sidebarService.onGetScannedFilesReport,
          order: 1000,
        },
        {
          isVisible: true,
          title: 'Data Custodian',
          link: 'dataCustodianDOWNLOAD',
          icon: BigidDataCustodianIcon,
          onItemClicked: sidebarService.onGetDataCustodianReport,
          order: 1100,
        },
        {
          isVisible: true,
          title: 'Usage Report',
          link: 'usageReportDOWNLOAD',
          icon: BigidUsageReportIcon,
          onItemClicked: sidebarService.onGetUsageReport,
          order: 1300,
        },
        {
          isVisible: true,
          title: 'Hotspots Report',
          link: CONFIG.states.HOTSPOTS_REPORT,
          icon: BigidHotspotIcon,
          selectedItemStates: [CONFIG.states.HOTSPOTS_REPORT],
          order: 1400,
        },
      ],
    },
  },
  {
    order: 500,
    name: 'DATA_MANAGEMENT',
    icon: BigidDataSourceOnlyIcon,
    title: 'Data Management',
    tooltipText: 'Data Management',
    onItemClicked: onNavigationItemClicked,
    link: 'dataManagementTEMP', //TODO: Replace when data management master page is added
    isQuickView: true,
    selectedItemStates: [
      'dataManagementTEMP',
      CONFIG.states.DATA_SOURCE_CONNECTIONS,
      CONFIG.states.SCANS_CONTROLLER,
      CONFIG.states.SCANS_NEW,
      CONFIG.states.SCANS,
      CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
      CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
      CONFIG.states.SCANS_SAVED_PROFILES,
      CONFIG.states.EDIT_SCAN_PROFILE,
      CONFIG.states.CREATE_SCAN_PROFILE,
      CONFIG.states.IDENTITY_SOURCE_CONNECTIONS,
      CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION,
      CONFIG.states.SECONDARY_CONNECTIONS,
      CONFIG.states.NEW_SECONDARY_CONNECTION,
      CONFIG.states.APPLICATIONS_SETUP,
      CONFIG.states.CLASSIFIERS,
      CONFIG.states.RULES,
      CONFIG.states.SCAN_WINDOW,
      CONFIG.states.TAGS,
      CONFIG.states.SAVED_QUERIES,
      CONFIG.states.SENSITIVITY_CLASSIFICATION,
      CONFIG.states.AUDIT,
      CONFIG.states.POLICIES,
      CONFIG.states.SCANS_SCAN_TEMPLATES,
      CONFIG.states.SCANS_PLANNED_SCANS,
      CONFIG.states.DATA_SOURCE_INIT,
      CONFIG.states.CREATE_SCAN,
      CONFIG.states.SCAN_TEMPLATE,
      CONFIG.states.AUTO_DISCOVERY,
      CONFIG.states.VENDORS,
      CONFIG.states.CURATION,
      CONFIG.states.RISK_REGISTER,
      CONFIG.states.RISK_LIBRARY,
      CONFIG.states.RISK_CONTROLS,
      CONFIG.states.FRAMEWORKS,
      CONFIG.states.FRAMEWORKS_CONTROLS,
    ],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink',
      emptyStateIcon: BigidDataManagementIllustration,
      emptyStateText: 'Click edit and turn on your Data Sources and Scans pages',
      onItemClicked: onNavigationItemClicked,
      menuItems: [
        {
          isVisible: true,
          title: 'Data Sources',
          link: CONFIG.states.DATA_SOURCE_ROUTER,
          icon: BigidDataSourceOnlyIcon,
          selectedItemStates: [
            CONFIG.states.DATA_SOURCE_CONNECTIONS,
            CONFIG.states.EDIT_DATA_SOURCE_CONNECTION,
            CONFIG.states.NEW_DATA_SOURCE_CONNECTION,
            CONFIG.states.DATA_SOURCE_INIT,
            CONFIG.states.DATA_SOURCE_ROUTER,
            CONFIG.states.AUTO_DISCOVERY,
          ],
          order: 100,
        },
        {
          isVisible: true,
          title: 'Scans',
          link: CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
          icon: BigidDataSearchIcon,
          selectedItemStates: [
            CONFIG.states.SCANS_NEW_SCANS_IN_PROGRESS,
            CONFIG.states.SCANS_NEW,
            CONFIG.states.SCANS,
            CONFIG.states.SCANS_SCAN_TEMPLATES,
            CONFIG.states.SCANS_PLANNED_SCANS,
            CONFIG.states.CREATE_SCAN,
            CONFIG.states.SCAN_TEMPLATE,
            CONFIG.states.SCANS_NEW_SCANS_COMPLETED,
            CONFIG.states.SCANS_SAVED_PROFILES,
            CONFIG.states.EDIT_SCAN_PROFILE,
            CONFIG.states.CREATE_SCAN_PROFILE,
            CONFIG.states.SCANS_CONTROLLER,
          ],
          order: 200,
        },
        {
          isVisible: true,
          title: 'Classifier Tuning',
          link: CONFIG.states.CURATION,
          icon: BigidCheckedViewIcon,
          selectedItemStates: [CONFIG.states.CURATION],
          order: 300,
        },
        {
          isVisible: true,
          title: 'Correlation Sets',
          link: CONFIG.states.IDENTITY_SOURCE_CONNECTIONS,
          icon: BigidCorrelationSetIcon,
          selectedItemStates: [CONFIG.states.IDENTITY_SOURCE_CONNECTIONS, CONFIG.states.NEW_IDENTITY_SOURCE_CONNECTION],
          order: 400,
        },
        {
          isVisible: true,
          title: 'Secondary Sources',
          link: CONFIG.states.SECONDARY_CONNECTIONS,
          icon: BigidSecondarySourcesIcon,
          selectedItemStates: [CONFIG.states.SECONDARY_CONNECTIONS, CONFIG.states.NEW_SECONDARY_CONNECTION],
          order: 500,
        },
        {
          isVisible: true,
          title: 'Assets',
          link: CONFIG.states.APPLICATIONS_SETUP,
          icon: BigidApplicationSetupIcon,
          selectedItemStates: [CONFIG.states.APPLICATIONS_SETUP],
          order: 600,
        },
        {
          isVisible: true,
          title: 'Classifier Management',
          link: CONFIG.states.CLASSIFIERS,
          icon: BigidClassifiersIcon,
          selectedItemStates: [CONFIG.states.CLASSIFIERS, CONFIG.states.CLASSIFIERS_MANAGEMENT],
          order: 700,
        },
        {
          isVisible: true,
          title: 'Compound Attributes',
          link: CONFIG.states.RULES,
          icon: BigidCompoundAttributesIcon,
          selectedItemStates: [CONFIG.states.RULES],
          order: 800,
        },
        {
          isVisible: true,
          title: 'Scan Windows',
          link: CONFIG.states.SCAN_WINDOW,
          icon: BigidScanWindowIcon,
          selectedItemStates: [CONFIG.states.SCAN_WINDOW],
          order: 900,
        },
        {
          isVisible: true,
          title: 'Tags',
          link: CONFIG.states.TAGS,
          icon: BigidTagIcon,
          selectedItemStates: [CONFIG.states.TAGS],
          order: 1000,
        },
        {
          isVisible: true,
          title: 'Saved Queries',
          link: CONFIG.states.SAVED_QUERIES,
          icon: BigidSavedQueriesIcon,
          selectedItemStates: [CONFIG.states.SAVED_QUERIES],
          order: 1100,
        },
        {
          isVisible: true,
          title: 'Sensitivity Classification',
          link: CONFIG.states.SENSITIVITY_CLASSIFICATION,
          icon: BigidSensitivityClassificationIcon,
          selectedItemStates: [CONFIG.states.SENSITIVITY_CLASSIFICATION],
          order: 1200,
        },
        {
          isVisible: true,
          title: 'Policies',
          link: CONFIG.states.POLICIES,
          icon: BigidPoliciesIcon,
          selectedItemStates: [CONFIG.states.POLICIES],
          order: 1300,
        },
        {
          isVisible: true,
          title: 'Risk Library',
          link: CONFIG.states.RISK_LIBRARY,
          icon: BigidFlameIcon,
          selectedItemStates: [CONFIG.states.RISK_LIBRARY],
          order: 1400,
        },
        {
          isVisible: true,
          title: 'Risk Controls',
          link: CONFIG.states.RISK_CONTROLS,
          icon: BigidShieldIcon,
          selectedItemStates: [CONFIG.states.RISK_CONTROLS],
          order: 1500,
        },
        {
          isVisible: true,
          title: 'Frameworks',
          link: CONFIG.states.FRAMEWORKS,
          icon: BigidFrameworkIcon,
          selectedItemStates: [CONFIG.states.FRAMEWORKS, CONFIG.states.FRAMEWORKS_CONTROLS],
          order: 1600,
        },
        ...(isShown(RISK_REGISTER_PERMISSIONS.ACCESS.name) && isRiskRegisterEnabled()
          ? [
              {
                isVisible: true,
                title: 'Risk Register',
                link: CONFIG.states.RISK_REGISTER,
                icon: BigidFlameIcon,
                selectedItemStates: [CONFIG.states.RISK_REGISTER],
                order: 1500,
              },
            ]
          : []),
      ],
    },
  },
  {
    order: 600,
    name: 'SETTINGS',
    icon: BigidAdministrationIcon,
    title: 'Settings',
    tooltipText: 'Settings',
    onItemClicked: onNavigationItemClicked,
    link: 'settingsTEMP', //TODO: Replace when settings master page is added
    selectedItemStates: [
      'settingsTEMP',
      CONFIG.states.GENERAL_SETTINGS,
      CONFIG.states.ADVANCED_TOOLS,
      CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
      CONFIG.states.CERTIFICATES_MANAGEMENT,
      CONFIG.states.PROXIES,
      CONFIG.states.CREDENTIALS,
      CONFIG.states.CREDENTIAL_PROVIDERS,
      CONFIG.states.ACTION_CENTER,
      CONFIG.states.ACTION_CENTER_AUDIT,
      CONFIG.states.ACTION_CENTER_ACTIONS,
      CONFIG.states.ACTION_CENTER_OVERVIEW,
      CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
      CONFIG.states.EMAIL_TEMPLATES,
      CONFIG.states.EMAIL_TEMPLATES_BROWSE,
      CONFIG.states.EMAIL_TEMPLATES_BRANDS,
    ],
    isQuickView: true,
    quickViewSchema: {
      onItemClicked: onNavigationItemClicked,
      onMasterPageLinkClicked: onNavigationItemClicked,
      // masterPageLink: 'masterLink', //TODO: Replace when settings master page is added
      emptyStateIcon: BigidSettingsIllustration,
      emptyStateText: 'Click edit and turn on your Settings',
      menuItems: [
        {
          title: 'General Settings',
          isVisible: true,
          link: CONFIG.states.GENERAL_SETTINGS,
          icon: BigidAdministrationIcon,
          selectedItemStates: [CONFIG.states.GENERAL_SETTINGS],
          order: 100,
        },
        {
          title: 'Access Management',
          isVisible: true,
          link: CONFIG.states.IDENTITY_ACCESS_MANAGEMENT,
          icon: BigidGroupIcon,
          selectedItemStates: [CONFIG.states.IDENTITY_ACCESS_MANAGEMENT],
          order: 200,
        },
        {
          title: 'Action Center',
          isVisible: true,
          link: CONFIG.states.ACTION_CENTER_ACTIONS,
          icon: BigidActionCenterIcon,
          selectedItemStates: [
            CONFIG.states.ACTION_CENTER_ACTIONS,
            CONFIG.states.ACTION_CENTER,
            CONFIG.states.ACTION_CENTER_AUDIT,
            CONFIG.states.ACTION_CENTER_OVERVIEW,
            CONFIG.states.ACTION_CENTER_CONFIGURATIONS,
          ],
          order: 300,
        },
        {
          title: 'Advanced Tools',
          isVisible: true,
          link: CONFIG.states.ADVANCED_TOOLS,
          icon: BigidAdvanceSearchIcon,
          selectedItemStates: [CONFIG.states.ADVANCED_TOOLS],
          order: 400,
        },
        {
          title: 'Certificates',
          isVisible: true,
          link: CONFIG.states.CERTIFICATES_MANAGEMENT,
          icon: BigidCertificatesIcon,
          selectedItemStates: [CONFIG.states.CERTIFICATES_MANAGEMENT],
          order: 500,
        },
        {
          title: 'Credentials',
          isVisible: true,
          link: CONFIG.states.CREDENTIALS,
          icon: BigidCredentialsIcon,
          selectedItemStates: [CONFIG.states.CREDENTIALS],
          order: 600,
        },
        {
          title: 'Credential Providers',
          isVisible: true,
          link: CONFIG.states.CREDENTIAL_PROVIDERS,
          icon: BigidVaultIcon,
          selectedItemStates: [CONFIG.states.CREDENTIAL_PROVIDERS],
          order: 700,
        },
        {
          title: 'Proxies',
          isVisible: true,
          link: CONFIG.states.PROXIES,
          icon: BigidProxyIcon,
          selectedItemStates: [CONFIG.states.PROXIES],
          order: 800,
        },
        {
          isVisible: true,
          title: 'Audit',
          link: CONFIG.states.AUDIT,
          icon: BigidHistoryIcon,
          selectedItemStates: [CONFIG.states.AUDIT],
          order: 900,
        },
        {
          isVisible: true,
          title: 'Email Templates',
          link: CONFIG.states.EMAIL_TEMPLATES_BROWSE,
          icon: BigidMessageIcon,
          selectedItemStates: [CONFIG.states.EMAIL_TEMPLATES_BROWSE, CONFIG.states.EMAIL_TEMPLATES_BRANDS],
          order: 1000,
        },
      ],
    },
  },
];

export const getInitialFooterItems = (onNavigationItemClicked: (link: string) => void): BigidSidebarItemType[] => [
  {
    icon: BigidApplicationsIcon,
    title: 'Apps Center',
    tooltipText: 'Apps Center',
    onItemClicked: onNavigationItemClicked,
    link: CONFIG.states.APPLICATIONS_MANAGEMENT,
    isQuickView: true,
    selectedItemStates: [CONFIG.states.APPLICATIONS_MANAGEMENT],
    quickViewSchema: {
      onMasterPageLinkClicked: onNavigationItemClicked,
      masterPageLink: CONFIG.states.APPLICATIONS_MANAGEMENT,
      emptyStateIcon: BigidSettingsIllustration,
      emptyStateText: 'Click edit and turn on your Settings',
      menuItems: [],
    },
    order: 0,
    workspaces: ALL_WORKSPACES,
    name: 'APPS_CENTER',
  },
];

export const getWorkspacesGettingStartedItems = (
  onNavigationItemClicked: (link: string) => void,
  workspaceId?: string,
): BigidSidebarItemType[] => {
  if (workspaceId === WorkspaceId.PRIVACY) {
    return [];
  }

  const workspaceSpecificItem = getWorkspaceGettingStartedItem(workspaceId) || {
    link: '',
    selectedItemStates: [],
    workspaces: [],
  };

  return [
    {
      ...getGettingStartedBasicProps(onNavigationItemClicked),
      ...workspaceSpecificItem,
    },
  ];
};

const getWorkspaceGettingStartedItem = (
  workspaceId?: string,
): { link: string; selectedItemStates: string[]; workspaces: WorkspaceId[] } | undefined => {
  switch (workspaceId) {
    case WorkspaceId.DISCOVERY: {
      return {
        link: CONFIG.states.GETTING_STARTED,
        selectedItemStates: [CONFIG.states.GETTING_STARTED],
        workspaces: [WorkspaceId.DISCOVERY],
      };
    }
    case WorkspaceId.SECURITY: {
      return {
        link: CONFIG.states.GETTING_STARTED_SECURITY,
        selectedItemStates: [CONFIG.states.GETTING_STARTED_SECURITY],
        workspaces: [WorkspaceId.SECURITY],
      };
    }
    default: {
      return undefined;
    }
  }
};

const getGettingStartedBasicProps = (
  onNavigationItemClicked: (link: string) => void,
): Omit<BigidSidebarItemType, 'link' | 'selectedItemStates'> => {
  return {
    icon: BigidSpaceshipDefaultIcon,
    title: 'Getting Started',
    tooltipText: 'Getting Started',
    name: 'GETTING_STARTED',
    isVisible: true,
    order: 0,
    onItemClicked: onNavigationItemClicked,
  };
};

const getNoWorkspacesGettingStartedItems = (
  onNavigationItemClicked: (link: string) => void,
): BigidSidebarItemType[] => {
  const gettingStartedPermitted = isShown(GETTING_STARTED_PERMISSIONS.ACCESS.name);
  const gettingStartedEnabled = isGettingStartedEnabled() && gettingStartedPermitted;
  const gettingStartedSecurityEnabled = isGettingStartedSecurityEnabled() && gettingStartedPermitted;
  if (gettingStartedEnabled && gettingStartedSecurityEnabled) {
    return [
      {
        ...getGettingStartedBasicProps(onNavigationItemClicked),
        isQuickView: true,
        link: 'gettingStartedTEMP',
        selectedItemStates: [
          'gettingStartedTEMP',
          CONFIG.states.GETTING_STARTED,
          CONFIG.states.GETTING_STARTED_SECURITY,
        ],
        quickViewSchema: {
          onMasterPageLinkClicked: onNavigationItemClicked,
          emptyStateIcon: BigidDataSourceIllustration,
          emptyStateText: 'Click edit and turn on your Getting Started',
          onItemClicked: onNavigationItemClicked,
          menuItems: [
            {
              isVisible: true,
              title: 'Discovery',
              link: CONFIG.states.GETTING_STARTED,
              icon: BigidSpaceshipDefaultIcon,
              selectedItemStates: [CONFIG.states.GETTING_STARTED],
            },
            {
              isVisible: true,
              title: 'Security',
              link: CONFIG.states.GETTING_STARTED_SECURITY,
              icon: BigidSpaceshipDefaultIcon,
              selectedItemStates: [CONFIG.states.GETTING_STARTED_SECURITY],
            },
          ],
        },
      },
    ];
  } else if (gettingStartedEnabled) {
    return getWorkspacesGettingStartedItems(onNavigationItemClicked, WorkspaceId.DISCOVERY);
  } else if (gettingStartedSecurityEnabled) {
    return getWorkspacesGettingStartedItems(onNavigationItemClicked, WorkspaceId.SECURITY);
  } else {
    return [];
  }
};

const getGettingStartedItems = (onNavigationItemClicked: (link: string) => void, workspaceId?: string) => {
  if (!isWorkspacesEnabled()) {
    return getNoWorkspacesGettingStartedItems(onNavigationItemClicked);
  }
  return getWorkspacesGettingStartedItems(onNavigationItemClicked, workspaceId);
};

export const FAVORITES_LINK = 'favoritesLinkPlaceholder';

export const getInitialHeaderItems = (
  onNavigationItemClicked: (link: string) => void,
  workspaceId?: string,
): BigidSidebarItemType[] => {
  return [
    ...getGettingStartedItems(onNavigationItemClicked, workspaceId),
    {
      icon: BigidStarIcon,
      title: 'Favorites',
      tooltipText: 'Favorites',
      isVisible: true,
      onItemClicked: onNavigationItemClicked,
      link: FAVORITES_LINK, //TODO: Replace when favorites master page is added
      hasDivider: true,
      isQuickView: true,
      selectedItemStates: [FAVORITES_LINK],
      isDeleteInQuickView: true,
      order: 0,
      workspaces: ALL_WORKSPACES,
      name: 'FAVORITES',
      quickViewSchema: {
        onItemClicked: onNavigationItemClicked,
        onMasterPageLinkClicked: onNavigationItemClicked,
        //masterPageLink: 'masterLink', //TODO: Uncomment when master page is added
        emptyStateIcon: BigidFavoritesIllustration,
        emptyStateText: 'Star your favorite pages',
        menuItems: [],
      },
    },
  ];
};

export function reorderFavoritesItemsByUserSettings(
  favoritesItemInMenu: BigidSidebarItemType[],
  userMenuItemsSettings: MenuSettingsItemBase[],
) {
  return favoritesItemInMenu
    .reduce<any>(
      (acc, favoritesItem, index) => {
        const indexInSettings = userMenuItemsSettings.findIndex(({ id }) => id === favoritesItem.link);
        if (indexInSettings > -1) {
          if (acc[indexInSettings] && acc[indexInSettings]?.id !== favoritesItem.link) {
            return [...acc, favoritesItem];
          }
          acc[indexInSettings] = favoritesItem;
          return acc;
        }

        return inserItemInMenu<BigidSidebarItemType>(acc, favoritesItem, index);
      },
      [...userMenuItemsSettings],
    )
    .filter((item: BigidSidebarItemType) => !!item.link);
}

export async function deleteAppsFromPreferences(appsIds?: string[]) {
  try {
    const {
      data: { favorites = [], recentlyUsed = [] },
    } = await userPreferencesService.get<ApplicationManagementPreferences>(APP_MANAGEMENT_USER_PREFERENCE_NAME);
    await userPreferencesService.update<ApplicationManagementPreferences>({
      preference: APP_MANAGEMENT_USER_PREFERENCE_NAME,
      data: {
        favorites: favorites?.filter(({ appId }) => !appsIds?.includes(appId)),
        recentlyUsed: recentlyUsed?.filter(({ appId }) => !appsIds?.includes(appId)),
      },
    });
    userPreferencesEventEmitter.emit(UserPreferencesEvents.UPDATE_FAVORITES_MANAGEMENT_PREFERENCES);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

type SideNavigationPreferences = {
  main: MenuSettings;
  header: MenuSettings;
};

export type SideNavigationPreferencesByWorkspaces = {
  workspaceId: string;
  preferences: SideNavigationPreferences;
}[];

export const getNavItemsDefaultSettingsByWorkspace = (
  navItems: BigidSidebarItemType[],
  workspace: WorkspaceId,
): MenuSettingsItem[] =>
  navItems.map(navItem => ({
    id: navItem.link,
    isVisible: (navItem.workspaces || getWorkspacesByNavLink(navItem.link)).includes(workspace),
    menuItems: navItem.quickViewSchema?.menuItems?.map(menuItem => ({
      id: menuItem.link,
      isVisible: (navItem.workspaces || getWorkspacesByNavLink(menuItem.link)).includes(workspace),
    })),
  }));

export const applyDefaultVisibilityByWorkspace = (navItems: BigidSidebarItemType[], workspace: WorkspaceId) =>
  navItems.map(navItem => ({
    ...navItem,
    isVisible: (navItem.workspaces || getWorkspacesByNavLink(navItem.link)).includes(workspace),
    quickViewSchema: {
      ...navItem.quickViewSchema,
      menuItems: navItem.quickViewSchema?.menuItems?.map(menuItem => ({
        ...menuItem,
        isVisible: (navItem.workspaces || getWorkspacesByNavLink(menuItem.link)).includes(workspace),
      })),
    },
  }));

export const getMenuSettingItemsByWorkspace = (
  navItems: BigidSidebarItemType[],
  workspace: WorkspaceId,
  settingsFromPreferences: MenuSettingsItem[] = [],
): MenuSettingsItem[] => {
  const resultSettings = cloneDeep(settingsFromPreferences);
  const defaultSettings = getNavItemsDefaultSettingsByWorkspace(navItems, workspace);

  defaultSettings.forEach(defaultSetting => {
    const resultSetting = resultSettings.find(resultSetting => resultSetting.id === defaultSetting.id);
    if (resultSetting) {
      if (defaultSetting.menuItems) {
        if (!resultSetting.menuItems) {
          resultSetting.menuItems = [];
        }
        defaultSetting.menuItems.forEach(defaultMenuItem => {
          const resultMenuItem = resultSetting.menuItems.find(
            resultMenuItem => resultMenuItem.id === defaultMenuItem.id,
          );
          if (!resultMenuItem) {
            resultSetting.menuItems.push(defaultMenuItem);
          }
        });
      }
    } else {
      resultSettings.push(defaultSetting);
    }
  });

  return resultSettings;
};

export const getSideNavigationPreferencesByWorkspace = async (
  navItems: BigidSidebarItemType[],
  workspaceId: string,
): Promise<SideNavigationPreferences> => {
  let result: SideNavigationPreferences = undefined;
  if (isWorkspacesEnabled()) {
    result = (
      await userPreferencesService.get<SideNavigationPreferencesByWorkspaces>(SIDE_NAVIGATION_BY_WORKSPACE)
    )?.data.find(preference => preference.workspaceId === workspaceId)?.preferences;
    if (!result) {
      result = { main: [], header: [] };
    }
    if (!result.main) {
      result.main = [];
    }
    result.main = getMenuSettingItemsByWorkspace(navItems, workspaceId as WorkspaceId, result.main);
  } else {
    result = (await userPreferencesService.get<SideNavigationPreferences>(PREFERENCES_NAME))?.data;
  }
  return { header: result?.header || [], main: result?.main || [] };
};

export const updateSideNavigationPreferencesByWorkspace = async (
  workspaceId: string,
  data: SideNavigationPreferences,
) => {
  if (isWorkspacesEnabled()) {
    const prevPreferences =
      (await userPreferencesService.get<SideNavigationPreferencesByWorkspaces>(SIDE_NAVIGATION_BY_WORKSPACE))?.data ||
      [];
    await userPreferencesService.update({
      preference: SIDE_NAVIGATION_BY_WORKSPACE,
      data: [
        { workspaceId, preferences: data },
        ...prevPreferences.filter(preference => preference.workspaceId !== workspaceId),
      ],
    });
  } else {
    await userPreferencesService.update({
      preference: PREFERENCES_NAME,
      data,
    });
  }
};

export const combineNavigationItems = (
  baseNavItems: BigidSidebarItemType[],
  additionalNavItems: BigidSidebarItemType[],
): BigidSidebarItemType[] => {
  const mergedNavItems = [...baseNavItems];
  additionalNavItems.forEach(additionalNavItem => {
    const mergedNavItemIndex = mergedNavItems.findIndex(mergedNavItem => mergedNavItem.name === additionalNavItem.name);
    if (mergedNavItemIndex === -1) {
      mergedNavItems.push(additionalNavItem);
    } else {
      const mergedNavItem = { ...mergedNavItems[mergedNavItemIndex] };
      mergedNavItems[mergedNavItemIndex] = mergedNavItem;
      mergedNavItem.selectedItemStates = [
        ...(mergedNavItem.selectedItemStates || []),
        ...(additionalNavItem.selectedItemStates || []),
      ];
      if (mergedNavItem.quickViewSchema || additionalNavItem.quickViewSchema) {
        mergedNavItem.quickViewSchema = { ...(mergedNavItem.quickViewSchema || additionalNavItem.quickViewSchema) };
        mergedNavItem.quickViewSchema.menuItems = [
          ...(mergedNavItem.quickViewSchema.menuItems || []),
          ...(additionalNavItem.quickViewSchema.menuItems || []),
        ];
      }
    }
  });
  return mergedNavItems;
};

export const sortNavItems = (navItems: BigidSidebarItemType[]): BigidSidebarItemType[] => {
  const sortedNavItems: BigidSidebarItemType[] = [];
  navItems.forEach(navItem => {
    const sortedNavItem = { ...navItem };
    if (navItem.quickViewSchema?.menuItems) {
      sortedNavItem.quickViewSchema = { ...navItem.quickViewSchema };
      sortedNavItem.quickViewSchema.menuItems = [...navItem.quickViewSchema.menuItems];
      sortedNavItem.quickViewSchema.menuItems.sort(
        (a: BigidSidebarMenuItemType, b: BigidSidebarMenuItemType) => a.order - b.order,
      );
    }
    sortedNavItems.push(sortedNavItem);
  });
  sortedNavItems.sort((a, b) => a.order - b.order);
  return sortedNavItems;
};

export const getAllNavLinksFromBigidNavigation = (navItems: BigidSidebarItemType[]): string[] => {
  return navItems.flatMap(navItem => navItem.selectedItemStates);
};

export const getSimilarityScoreForNavLinks = (link1: string, link2: string): number => {
  let score = 0;
  const split1 = link1.split('/');
  const split2 = link2.split('/');
  const shortestSplitLength = split1.length > split2.length ? split2.length : split1.length;
  for (let i = 0; i < shortestSplitLength; i++) {
    if (split1[i] === split2[i]) {
      score++;
    } else {
      break;
    }
  }
  return score;
};

export const getMostSimilarSelectedLink = (allLinks: string[], selectedLink: string): string => {
  let lastComparedLink = selectedLink;
  let lastComparedLinkScore = 0;
  for (let i = 0; i < allLinks.length; i++) {
    const link = allLinks[i];
    if (link === selectedLink) return link;
    const linkScore = getSimilarityScoreForNavLinks(link, selectedLink);
    if (linkScore > lastComparedLinkScore) {
      lastComparedLink = link;
      lastComparedLinkScore = linkScore;
    }
  }
  return lastComparedLink;
};

export const getAppsNavLinks = (
  customApps: CustomApps[],
  privacyPortalNavLinks: NavigationMenuItem[],
  onNavigationItemClicked: (link: string) => void,
) => {
  const extendedApps = customApps.map(customApp => {
    if (isPrivacyPortal(customApp)) {
      const extendedPrivacyPortal = { ...customApp };
      extendedPrivacyPortal.workspaces_details = { ...extendedPrivacyPortal.workspaces_details };
      extendedPrivacyPortal.workspaces_details.sidebar_items = privacyPortalNavLinks;
      return extendedPrivacyPortal;
    }
    return customApp;
  });
  return extendedApps.flatMap(customApp => getSidebarItemsFromCustomApp(customApp, onNavigationItemClicked));
};
