import { BigidBody1, BigidCheckbox, BigidShareUrl, BigidTooltip, TertiaryButton } from '@bigid-ui/components';
import { BigidAddTagIcon, BigidExportIcon, BigidRemoveTagIcon } from '@bigid-ui/icons';
import { styled } from '@mui/material';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';
import { getDataExplorerSearchResultsCSV } from '../../../../explorerSearchResultsService';
import { useDataCatalogSearchResultsContext } from '../../../../contexts/dataCatalogSearchResultsContext';
import { useLocalTranslation } from '../../../../translations';
import { WithDataAid } from '../../../../types';
import { DataExplorerBulkTagAssignmentDialog } from './DataExplorerBulkTagAssignmentDialog';
import { TagAssignmentMode } from './actionsService';
import {
  sendDataExplorerResultsPageAddTagCloseBiAnalytics,
  sendDataExplorerResultsPageAddTagOpenBiAnalytics,
  sendDataExplorerResultsPageAddTagSubmitBiAnalytics,
  sendDataExplorerResultsPageRemoveTagCloseBiAnalytics,
  sendDataExplorerResultsPageRemoveTagOpenBiAnalytics,
  sendDataExplorerResultsPageRemoveTagSubmitBiAnalytics,
  sendDataExplorerResultsPageExportSubmitBiAnalytics,
  sendDataExplorerResultsPageShareCopyBiAnalytics,
  sendDataExplorerResultsPageSelectAllBiAnalytics,
  DataExplorerSelectAllToggleBiPayload,
} from '../../../../../DataExplorer/services/analytics';
import { getActionFilterString } from './actionUtils';
import { SortingControl } from '../../SortingControl';
import { generateDataAid } from '@bigid-ui/utils';
import { ItemsCount } from './ItemsCount';

export const ActionsContainer = styled('div')<{ paddingLeft?: number }>`
  display: flex;
  gap: 8px;
  padding: 8px 12px 8px ${({ paddingLeft }) => paddingLeft || 0}px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.bigid.gray200};
`;

export const ActionsSection = styled('div')<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap || 0}px;
`;

export const VerticalDivider = styled('div')`
  width: 1px;
  height: 20px;
  margin-right: 12px;
  margin-left: 12px;
  background-color: ${({ theme }) => theme.palette.bigid.gray200};
`;

type BulkTagDialogConfig = {
  isOpen: boolean;
  type: TagAssignmentMode;
};

export const Actions: FC<WithDataAid> = ({ dataAid = 'Actions' }) => {
  const [bulkTagDialogConfig, setBulkTagDialogConfig] = useState<BulkTagDialogConfig>({
    isOpen: false,
    type: null,
  });
  const {
    state: { gridConfig, savedFiltersMap },
    entityType,
    query,
    dispatchCatalogSearchAction,
    isGridDataLoading,
  } = useDataCatalogSearchResultsContext();
  const { selectedRowsIds, isSelectAll, totalCount, unselectedRowsIds, rows } = gridConfig;
  const filter = getActionFilterString({
    filter: savedFiltersMap[entityType],
    isSelectAll,
    selectedRowsIds,
    unselectedRowsIds,
  });

  const { t } = useLocalTranslation('actions');

  const handleExportButtonClick = () => {
    getDataExplorerSearchResultsCSV({
      searchText: query,
      filter,
    });

    sendDataExplorerResultsPageExportSubmitBiAnalytics();
  };

  const handleAssignTagButtonClick = () => {
    setBulkTagDialogConfig({
      isOpen: true,
      type: 'assign',
    });

    sendDataExplorerResultsPageAddTagOpenBiAnalytics();
  };

  const handleUnassignTagButtonClick = () => {
    setBulkTagDialogConfig({
      isOpen: true,
      type: 'unassign',
    });

    sendDataExplorerResultsPageRemoveTagOpenBiAnalytics();
  };
  const areActionsHidden = selectedRowsIds.length === 0 && !isSelectAll;
  const areTagsActionsDisabled =
    (!Boolean(query) && isSelectAll && unselectedRowsIds.length === 0 && !filter) || areActionsHidden;

  const handleBulkTagDialogClose = (mode: TagAssignmentMode) => {
    switch (mode) {
      case 'assign':
        sendDataExplorerResultsPageAddTagCloseBiAnalytics();
        break;
      case 'unassign':
        sendDataExplorerResultsPageRemoveTagCloseBiAnalytics();
        break;
    }

    setBulkTagDialogConfig(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const handleBulkTagDialogSubmit = (mode: TagAssignmentMode) => {
    switch (mode) {
      case 'assign':
        sendDataExplorerResultsPageAddTagSubmitBiAnalytics();
        break;
      case 'unassign':
        sendDataExplorerResultsPageRemoveTagSubmitBiAnalytics();
        break;
    }

    setBulkTagDialogConfig(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const isIntermediate = useMemo(() => {
    if (isSelectAll) {
      return unselectedRowsIds.length > 0;
    }
    return selectedRowsIds.length > 0;
  }, [isSelectAll, selectedRowsIds, unselectedRowsIds]);

  const areRowsSelected = selectedRowsIds.length > 0 || isSelectAll;
  const count = selectedRowsIds.length || totalCount;

  const countTranslationKey = areRowsSelected ? 'selected' : 'items';

  const handleCheckboxChange = (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    dispatchCatalogSearchAction({ type: 'TOGGLE_SELECT_ALL' });

    const biPayload: DataExplorerSelectAllToggleBiPayload = {
      isSelectAllToggled: checked,
    };

    sendDataExplorerResultsPageSelectAllBiAnalytics(biPayload);
  };

  const tagsActionsTooltip = areTagsActionsDisabled
    ? t('tagAssignment.unassign.toolTipWhenDisabled')
    : t('tagAssignment.unassign.toolTipWhenNotDisabled');

  return (
    <ActionsContainer paddingLeft={32}>
      <ActionsSection gap={5}>
        <BigidCheckbox
          dataAid="selectAllCheckbox"
          indeterminate={isIntermediate}
          checked={isSelectAll}
          onChange={handleCheckboxChange}
          label={<ItemsCount count={count} translationKey={countTranslationKey} />}
          disabled={!rows?.length || isGridDataLoading}
        />
        <VerticalDivider />
        <BigidTooltip title={tagsActionsTooltip} placement="bottom">
          <div>
            <TertiaryButton
              onClick={handleAssignTagButtonClick}
              text={t('tagAssignment.assign.title')}
              disabled={areTagsActionsDisabled}
              startIcon={<BigidAddTagIcon />}
              size="medium"
              dataAid="BigidToolbarAction-global-assign-tag"
            />
          </div>
        </BigidTooltip>
        <BigidTooltip title={tagsActionsTooltip} placement="bottom">
          <div>
            <TertiaryButton
              onClick={handleUnassignTagButtonClick}
              text={t('tagAssignment.unassign.title')}
              disabled={areTagsActionsDisabled}
              startIcon={<BigidRemoveTagIcon />}
              dataAid="BigidToolbarAction-global-remove-tag"
              size="medium"
            />
          </div>
        </BigidTooltip>
        {!areActionsHidden && (
          <TertiaryButton
            startIcon={<BigidExportIcon />}
            onClick={handleExportButtonClick}
            text={t('export')}
            dataAid="BigidToolbarAction-global-export"
            size="medium"
          />
        )}
      </ActionsSection>
      <ActionsSection>
        {!areRowsSelected && (
          <BigidShareUrl shareUrl={window.location.href} onCopy={sendDataExplorerResultsPageShareCopyBiAnalytics} />
        )}
        <VerticalDivider />
        <SortingControl />
      </ActionsSection>

      <DataExplorerBulkTagAssignmentDialog
        isOpen={bulkTagDialogConfig.isOpen}
        onClose={handleBulkTagDialogClose}
        onSubmit={handleBulkTagDialogSubmit}
        filter={filter}
        searchText={query}
        mode={bulkTagDialogConfig.type}
      />
    </ActionsContainer>
  );
};
