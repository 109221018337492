import React, { FC, useEffect, useMemo } from 'react';
import { styled } from '@mui/material';
import { AdvancedToolbarOverrideValueStrategy, BigidAdvancedToolbarFilter, PrimaryButton } from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { ExecutiveDashboardProps } from './executiveDashboardServices';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { useLocalTranslation } from './translations';
import { SavedFiltersTabs } from './components';

const Root = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 0px 10px 8px;
  position: relative;
`;

const ChildrenWrapper = styled('div')`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const FilterToolbarWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.vars.palette.bigid.gray200}`,
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 12px',
  borderRadius: '4px',
  boxShadow: '0px 1px 12px 0px rgba(0, 0, 0, 0.05)',
  marginBottom: '16px',
  '& div:first-child': {
    borderRadius: '4px',
  },
}));

export const ExecutiveDashboard: FC<ExecutiveDashboardProps> = ({
  dashboardType,
  dashboardId,
  isExportButtonDisplayed,
  isSavedFiltersTabsDisplayed,
  onExportButtonClick,
  toolbarFilters,
  onFiltersChange,
  updateExternalFilters,
  activeFilters,
  externalAppliedFilters,
  children,
}) => {
  const { t } = useLocalTranslation();

  const selectedOptionsConfig = useMemo(
    () => ({
      values: externalAppliedFilters,
      strategy: AdvancedToolbarOverrideValueStrategy.INIT_WITHOUT_RELOAD_OVERRIDE,
    }),
    [externalAppliedFilters],
  );

  useEffect(() => {
    pageHeaderService.setTitle({
      pageTitle: t(`titles.${dashboardType}`),
      rightSideComponentsContainer: isExportButtonDisplayed && (
        <PrimaryButton size="medium" onClick={() => onExportButtonClick?.()} text={t('buttonLables.exportPdf')} />
      ),
    });
  }, [dashboardType, isExportButtonDisplayed, onExportButtonClick, t]);

  return (
    <Root data-aid={generateDataAid(dashboardId, ['dashboard', 'layout'])}>
      {isSavedFiltersTabsDisplayed && (
        <SavedFiltersTabs
          dashboardId={dashboardId}
          updateExternalFilters={updateExternalFilters}
          activeFilters={activeFilters}
        />
      )}

      <FilterToolbarWrapper>
        <BigidAdvancedToolbarFilter
          selectedOptionsConfig={selectedOptionsConfig}
          onFiltersChange={onFiltersChange}
          filters={toolbarFilters}
          shouldAsyncFiltersReload={false}
        />
      </FilterToolbarWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Root>
  );
};
