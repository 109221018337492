import React, { FC, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarFilterUnion,
  BigidButtonIcon,
  BigidFormField,
  BigidFormProps,
  BigidFormStateAndHandlers,
  BigidFormValidateOnTypes,
  BigidLoader,
  TertiaryButton,
} from '@bigid-ui/components';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidAddIcon, BigidDeleteIcon } from '@bigid-ui/icons';
import {
  saveNewFilterWithDialog,
  deleteFilterWithDialog,
  DashboardFilterTab,
  getSavedFilters,
  isFilterNameAleadyTaken,
  SavedFiltersItem,
} from '../executiveDashboardServices';
import { isNameValid } from '../../../utilities/validation';
import { useLocalTranslation } from '../translations';

export interface SavedFiltersTabsProps {
  dashboardId: string;
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  updateExternalFilters: (filters: AdvancedToolbarOverrideValue[]) => void;
}

const SavedFiltersTabsContainer = styled('div')`
  height: 40px;
  width: 100%;
  display: flex;
  margin-bottom: 8px;
`;

const LoaderWrapper = styled('div')`
  width: 100px;
  display: flex;
`;

const TabsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const SavedFiltersTabs: FC<SavedFiltersTabsProps> = ({ dashboardId, activeFilters, updateExternalFilters }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [filterTabs, setFilterTabs] = useState<DashboardFilterTab[]>([]);
  const newFilterFormControls = useRef<BigidFormStateAndHandlers>();
  const { t } = useLocalTranslation();

  const updateFilterTabs = (newFilters: SavedFiltersItem[]) => {
    const updatedFiltersTabs = newFilters.map(item => ({
      filter: JSON.parse(item.query),
      id: item.id,
      label: item.text,
    }));
    setFilterTabs(updatedFiltersTabs);
  };

  useEffect(() => {
    const updateSavedFilters = async () => {
      const fetchedFilters = await getSavedFilters(dashboardId);
      updateFilterTabs(fetchedFilters);
      setIsLoading(false);
    };

    updateSavedFilters();
  }, [dashboardId]);

  const fields: BigidFormField[] = [
    {
      name: 'filterName',
      label: 'New filter name',
      validate: (name: string) => {
        if (!isNameValid(name)) {
          return t('errorMessages.invalidFilterName');
        }
        if (isFilterNameAleadyTaken(name, filterTabs)) {
          return t('errorMessages.nameAlreadyTaken');
        }
        return false;
      },
    },
  ];

  const newFilterDialogFormProps: BigidFormProps = {
    controlButtons: false,
    validateOn: BigidFormValidateOnTypes.SUBMIT,
    stateAndHandlersRef: newFilterFormControls,
    fields,
  };

  const onTabClick = (event: React.MouseEvent<Element, MouseEvent>, filter: BigidAdvancedToolbarFilterUnion[]) => {
    event.preventDefault();
    updateExternalFilters(filter);
  };

  const onAddNewFilterClick = async () => {
    await saveNewFilterWithDialog(activeFilters, dashboardId, newFilterDialogFormProps);
    const fetchedFilters = await getSavedFilters(dashboardId);
    updateFilterTabs(fetchedFilters);
  };

  const onDeleteFilterClick = async (filterId: string, filterName: string) => {
    const isFilterDeleted = await deleteFilterWithDialog(filterId, filterName);
    if (isFilterDeleted) {
      const updatedFilterTabs = [...filterTabs];
      setFilterTabs(updatedFilterTabs.filter(item => item.id !== filterId));
    }
  };

  return (
    <SavedFiltersTabsContainer data-aid={generateDataAid(dashboardId, ['saved-filters-tabs', 'container'])}>
      {isLoading ? (
        <LoaderWrapper>
          <BigidLoader thickness={2} size={20} position="relative" />
        </LoaderWrapper>
      ) : (
        <TabsWrapper>
          {filterTabs.map(filterItem => (
            <>
              <TertiaryButton
                key={filterItem.id}
                text={filterItem.label}
                onClick={(event?: React.MouseEvent<Element, MouseEvent>) => onTabClick(event, filterItem.filter)}
                size={'small'}
              />
              <BigidButtonIcon
                icon={BigidDeleteIcon}
                onClick={() => onDeleteFilterClick(filterItem.id, filterItem.label)}
                size="small"
              />
            </>
          ))}
          {!!activeFilters?.length && (
            <TertiaryButton
              text={t('buttonLables.addFilter')}
              endIcon={<BigidAddIcon />}
              size="small"
              onClick={onAddNewFilterClick}
            />
          )}
        </TabsWrapper>
      )}
    </SavedFiltersTabsContainer>
  );
};
