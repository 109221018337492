import React from 'react';
import { TopFailedWidget } from './TopFailedWidget/TopFailedWidget';
import { TopRiskWidget } from './TopRiskWidget/TopRiskWidget';
import { DataSourceWidget } from './DataSourceWidget/DataSourceWidget';
import { FailedControlsWidget } from './FailedControlsWidget/FailedControlsWidget';
import { ContentWrapper, WidgetWrapper, WidgetsSection } from './WidgetsStyles';
import { generateDataAid } from '@bigid-ui/utils';
import { BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';

interface WidgetsContainerProps {
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  dataAid?: string;
}

export const WidgetsContainer = ({ dataAid = 'complianceDashboard' }: WidgetsContainerProps) => {
  return (
    <ContentWrapper data-aid={generateDataAid(dataAid, ['container'])}>
      <WidgetWrapper>
        <FailedControlsWidget data={[]} dataAid={generateDataAid(dataAid, ['failedControls', 'widget'])} />
      </WidgetWrapper>
      <WidgetsSection>
        <WidgetWrapper>
          <TopFailedWidget data={[]} dataAid={generateDataAid(dataAid, ['topFailed', 'widget'])} />
        </WidgetWrapper>
        <WidgetWrapper>
          <TopRiskWidget data={[]} dataAid={generateDataAid(dataAid, ['topRisk', 'widget'])} />
        </WidgetWrapper>
      </WidgetsSection>
      <WidgetWrapper>
        <DataSourceWidget data={[]} dataAid={generateDataAid(dataAid, ['dataSource', 'widget'])} />
      </WidgetWrapper>
    </ContentWrapper>
  );
};
