import { PropsWithChildren } from 'react';
import {
  AdvancedToolbarOverrideValue,
  BigidAdvancedToolbarFilterUnion,
  BigidFormProps,
  BigidFormValues,
  PrimaryButton,
} from '@bigid-ui/components';
import { closeSystemDialog, openSystemDialog } from '../../services/systemDialogService';
import { notificationService } from '../../services/notificationService';
import { httpService } from '../../services/httpService';
import { camelCase } from 'lodash';
import { getCurrentUser } from '../../utilities/systemUsersUtils';
import { NewFilterDialogContent, DeleteFilterDialogContent } from './components';
import { getFixedT } from './translations';

const SAVED_FILTERS_API_ROUTE = 'saved-filters';
const t = getFixedT();

export enum DashboardType {
  SECURITY = 'security',
  DSPM = 'dspm',
  DISCOVERY = 'discovery',
  COMPLIANCE = 'compliance',
}

export type DashboardFilterTab = {
  id: string;
  label: string;
  filter: BigidAdvancedToolbarFilterUnion[];
};

export interface SavedFiltersItem {
  createdAt: string;
  filter: string;
  id: string;
  name: string;
  query: string;
  text: string;
  type: string;
  updatedAt: string;
}

export interface ExecutiveDashboardProps extends PropsWithChildren {
  dashboardType: DashboardType;
  dashboardId: string;
  isExportButtonDisplayed?: boolean;
  isSavedFiltersTabsDisplayed?: boolean;
  toolbarFilters: BigidAdvancedToolbarFilterUnion[];
  activeFilters: BigidAdvancedToolbarFilterUnion[];
  onFiltersChange: (filters: BigidAdvancedToolbarFilterUnion[], isBusy?: boolean) => void;
  updateExternalFilters: (filters: AdvancedToolbarOverrideValue[]) => void;
  onExportButtonClick?: (event?: MouseEvent) => void;
  externalAppliedFilters?: AdvancedToolbarOverrideValue[];
}

export const saveNewFilterWithDialog = (
  filter: BigidAdvancedToolbarFilterUnion[],
  dashboardId: string,
  newFilterDialogFormProps: BigidFormProps,
) => {
  return new Promise<DashboardFilterTab[]>(resolve => {
    openSystemDialog({
      title: t('titles.saveFilterDialog'),
      onClose: () => resolve([]),
      buttons: [
        {
          text: t('buttonLables.save'),
          onClick: () => {
            try {
              newFilterDialogFormProps?.stateAndHandlersRef?.current?.validateAndSubmit(
                async (values: BigidFormValues) => {
                  const filterLabel = values.filterName.trim();
                  const filterName = camelCase(filterLabel);
                  await saveNewFilter(dashboardId, filterLabel, filterName, filter);
                  resolve([{ id: filterName, label: filterLabel, filter }]);
                  closeSystemDialog();
                },
              );
            } catch (e) {
              notificationService.error(t('errorMessages.cantSaveFilter'));
              console.error(e);
              resolve([]);
            }
          },
          component: PrimaryButton,
        },
      ],
      content: NewFilterDialogContent,
      contentProps: { newFilterDialogFormProps },
      borderTop: true,
      maxWidth: 'xs',
    });
  });
};

export const prepareFiltersForSaving = (filter: BigidAdvancedToolbarFilterUnion[]) => {
  const updatedFilters = filter.map(({ type, id, options }) => ({
    type,
    id,
    options,
  }));
  return JSON.stringify(updatedFilters);
};

export const saveNewFilter = async (
  dashboardId: string,
  filterLabel: string,
  filterId: string,
  filter: BigidAdvancedToolbarFilterUnion[],
) => {
  const { name } = await getCurrentUser();
  const data = {
    name: filterId,
    text: filterLabel,
    query: prepareFiltersForSaving(filter),
    type: dashboardId,
    userId: name,
  };
  return await httpService.post(SAVED_FILTERS_API_ROUTE, data);
};

export const deleteFilter = async (filterId: string) =>
  await httpService.delete(`${SAVED_FILTERS_API_ROUTE}/${filterId}`);

export const getSavedFilters = async (dashboardId: string) => {
  try {
    const {
      data: { data = [] },
    } = await httpService.fetch<{ data: SavedFiltersItem[] }>(`${SAVED_FILTERS_API_ROUTE}?type=${dashboardId}`);
    return data;
  } catch (e) {
    notificationService.error(t('errorMessages.cantFetchFilters'));
    console.error(e);
  }
};

export const isFilterNameAleadyTaken = (name: string, filterTabs: DashboardFilterTab[]) => {
  const isNameExist = (filter: DashboardFilterTab) => filter.label === name;
  return !!filterTabs.find(isNameExist);
};

export const deleteFilterWithDialog = (filterId: string, filterName: string) => {
  return new Promise<boolean>(resolve => {
    openSystemDialog({
      title: t('titles.deleteFilterDialog', { filterName }),
      onClose: () => resolve(false),
      buttons: [
        {
          text: t('buttonLables.delete'),
          onClick: async () => {
            try {
              await deleteFilter(filterId);
              resolve(true);
              closeSystemDialog();
            } catch (e) {
              notificationService.error(t('errorMessages.cantDeleteFilter'));
              console.error(e);
              resolve(false);
            }
          },
          component: PrimaryButton,
        },
      ],
      content: DeleteFilterDialogContent,
      contentProps: { filterName },
      borderTop: true,
      maxWidth: 'xs',
    });
  });
};
