import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidBody1 } from '@bigid-ui/components';
import { useLocalTranslation } from '../translations';

const DialogContainer = styled('div')`
  display: flex;
  flex-direction: 'column';
  align-items: 'center';
`;

export const DeleteFilterDialogContent: FC = () => {
  const { t } = useLocalTranslation();
  return (
    <DialogContainer>
      <BigidBody1>{t('bodyText.deleteFilterMessage')}</BigidBody1>
    </DialogContainer>
  );
};
