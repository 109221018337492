import React, { FC } from 'react';
import { styled } from '@mui/material';
import { BigidFormProps, BigidForm } from '@bigid-ui/components';

export interface NewFilterDialogContentProps {
  newFilterDialogFormProps: BigidFormProps;
}

const DialogContainer = styled('div')`
  display: flex;
  flex-direction: 'column';
  align-items: 'flex-start';
`;

export const NewFilterDialogContent: FC<NewFilterDialogContentProps> = ({ newFilterDialogFormProps }) => {
  return (
    <DialogContainer>
      <BigidForm {...newFilterDialogFormProps} />
    </DialogContainer>
  );
};
