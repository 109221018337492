import { BigidFieldFilterOperator } from '@bigid-ui/components';
import { httpService } from '../services/httpService';
import { notificationService } from '../services/notificationService';
import { SystemEvents, systemEventsEmitter } from '../services/systemEvents';
import { queryService } from '../services/queryService';
import { dataSourceConnectionsService } from '../services/angularServices';
import { uniqBy } from 'lodash';
import { DiscoveryAppType } from '../views/DataSources/DataSourceConnections/hooks/useAutoDiscoveryAppStatus';
import { ScanTypes } from '../views/Scans/ScanTemplates/scanTemplateTypes';

interface GetDSQueryProps {
  maxDS?: number;
  searchString?: string;
  onlyCSSupported?: boolean;
  requireTotalCount?: boolean;
  operator?: BigidFieldFilterOperator;
}

export enum DataSourceTemplateStatus {
  NEW = 'NEW',
  PREMIUM = 'PREMIUM',
  DEPRECATED = 'DEPRECATED',
}

export interface DataSource {
  isPermitted: boolean;
  id: string;
  name: string;
  modelProp: string;
  isDim?: boolean;
  nameInDocs?: string;
  displayName?: string;
  keywords?: string[];
  templateName?: string;
  type?: string;
  isFromDiscoveryApp?: boolean;
  discoveryAppType?: DiscoveryAppType;
  supportedScanTypes: ScanTypes[];
  status?: DataSourceTemplateStatus;
  isCreateDisabled?: boolean;
}

export interface SupportedDataSources<T> {
  data?: T;
}

export const DEFAULT_MAX_DS = 400;

let supportedDataSourcesNaiveCache: DataSource[];

const updateSupportedDataSourceNaiveCache = async () => {
  try {
    const {
      data: { data },
    } = await httpService.fetch<SupportedDataSources<DataSource[]>>('ds-connections-types');
    supportedDataSourcesNaiveCache = data;
    return supportedDataSourcesNaiveCache;
  } catch (error) {
    return error;
  }
};

export const getSupportedDataSources = async (
  filterNotCreatable = false,
  onStart?: () => void,
): Promise<DataSource[]> => {
  try {
    if (onStart && !supportedDataSourcesNaiveCache) {
      onStart();
    }
    const result = supportedDataSourcesNaiveCache || (await updateSupportedDataSourceNaiveCache());
    return filterNotCreatable ? result.filter(({ isCreateDisabled }: DataSource) => !isCreateDisabled) : result;
  } catch (error) {
    notificationService.error('Error getting data sources types');
  }
};

export async function getDataSourcesListWithSelection(searchString?: string) {
  let selectedDataSource = null;
  const { ds_connections: availableDsConnections, totalCount } = await getListOfAvailableDataSources();

  if (searchString) {
    const selectedDsQuery = getDsConnectionsQuery({
      operator: 'equal',
      searchString,
      maxDS: 1,
    });

    const {
      data: {
        ds_connections: [selected],
      },
    } = await dataSourceConnectionsService.getDSConnectionDataByQuery(selectedDsQuery);
    selectedDataSource = selected;
  }

  if (selectedDataSource) {
    availableDsConnections.push(selectedDataSource);
  }

  return {
    selectedDataSource,
    availableDsConnections: uniqBy(availableDsConnections, 'name'),
    totalCount,
  };
}

async function getListOfAvailableDataSources() {
  const query = getDsConnectionsQuery({ maxDS: 50 });
  const {
    data: { ds_connections, totalCount },
  } = await dataSourceConnectionsService.getDSConnectionDataByQuery(query);
  return { ds_connections, totalCount };
}

export const getDsConnectionsQuery = ({
  maxDS = DEFAULT_MAX_DS,
  searchString,
  onlyCSSupported = true,
  requireTotalCount,
}: GetDSQueryProps): string =>
  queryService.getGridConfigQuery({
    skip: 0,
    limit: maxDS,
    ...(requireTotalCount && { requireTotalCount }),
    filter: [
      ...(searchString
        ? [{ field: 'name', operator: 'textSearch' as BigidFieldFilterOperator, value: searchString }]
        : []),
      ...(onlyCSSupported
        ? [{ field: 'is_idsor_supported', operator: 'equal' as BigidFieldFilterOperator, value: true }]
        : []),
    ],
  });

export const hasNewTemplate = (dataSourceType: string) =>
  supportedDataSourcesNaiveCache.find(({ type, templateName }) => type === dataSourceType && !!templateName);

systemEventsEmitter.addEventListener(SystemEvents.UPDATE_LICENSE, updateSupportedDataSourceNaiveCache);

export function fastCheckStringArraysEqual(ids: string[], newIds: string[]) {
  if (ids.length !== newIds.length) {
    return false;
  }
  const set = new Set(ids);

  for (const item of newIds) {
    if (!set.has(item)) {
      return false;
    }
  }

  return true;
}
