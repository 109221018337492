import { BigidFlexibleListCardContentComponent } from '@bigid-ui/components';
import { IconComponentProps } from '@bigid-ui/icons';
import React, { ComponentType, ReactNode, ReactText } from 'react';
import { CONFIG } from '../../../../../config/common';
import { $state } from '../../../../services/angularServices';
import { CatalogSearchGridRow } from '../../types';
import { getAlienIconByType } from '../../utils';
import { AlienEntityTitle } from './components/AlienEnityTitle';
import { ModifiedDate } from './components/ModifiedDate';
import { mapFieldValueToBiqlString } from '../../../../services/filtersToBiqlService';

const getOnClickHandler = ({ id, entityType, data, title }: EntityRow) => {
  switch (entityType) {
    case 'policy': {
      return () => {
        const { id: policyId } = data;
        $state.go(CONFIG.states.POLICIES, { policyId });
      };
    }
    case 'datasource': {
      return () => {
        const filter = mapFieldValueToBiqlString('system', String(id));
        $state.go(CONFIG.states.CATALOG, { filter });
      };
    }
  }
};

type EntityRow = CatalogSearchGridRow<'datasource' | 'policy'>;

export const alienEntityAdapter = (row: CatalogSearchGridRow<'datasource' | 'policy'>) => {
  const {
    data: { modifiedDate, id, owners, name },
    highlights,
    entityType,
  } = row;
  const Icon = getAlienIconByType(entityType);
  const handleTitleClick = getOnClickHandler(row);
  const highlightOwnersValue =
    highlights.find(highlight => highlight.fieldName === 'owner')?.highlightedValue || owners;

  const titleHighlight = highlights.find(highlight => highlight.fieldName === 'name');
  const cardObject: CardObject = {
    contentComponent: () => <ModifiedDate date={modifiedDate} />,
    icon: () => <Icon size="large" />,
    id,
    titleComponent: (
      <AlienEntityTitle owners={highlightOwnersValue} title={titleHighlight?.highlightedValue[0] || name} />
    ),
    onTitleClick: handleTitleClick,
  };

  return cardObject;
};

type CardObject = {
  icon: ComponentType<IconComponentProps & React.SVGProps<SVGSVGElement>>;
  titleComponent: ReactNode;
  footerComponent?: ReactNode;
  contentComponent: BigidFlexibleListCardContentComponent;
  id: ReactText;
  onTitleClick?: () => void;
};
