import React from 'react';
import { BigidBody1, BigidHeading5 } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { EmptyState } from '../EmptyState/EmptyState';
import { Main, TitleWrapper, DescriptionWrapper, UrlWrapper, Content } from '../WidgetsStyles';
import { $state } from '../../../../../services/angularServices';
import { CONFIG } from '../../../../../../config/common';
import { generateDataAid } from '@bigid-ui/utils';

interface FailedControlsWidgetProps {
  data: any[];
  dataAid: string;
}

export const FailedControlsWidget = ({ data, dataAid }: FailedControlsWidgetProps) => {
  const { t } = useLocalTranslation('compliance.widgets.failedControls');

  const handleRedirect = () => {
    $state.go(CONFIG.states.POLICIES);
  };

  return (
    <Main widgetHeigth="234px" data-aid={dataAid}>
      <TitleWrapper textDirection="column">
        <BigidHeading5>{t('title')}</BigidHeading5>
        <DescriptionWrapper>
          <BigidBody1 fontSize={12}>{t('compoundText.part1')}</BigidBody1>
          <UrlWrapper
            data-aid={generateDataAid(dataAid, ['emptyState', 'policiesLink'])}
            fontSize={12}
            onClick={handleRedirect}
          >
            {t('compoundText.part2')}
          </UrlWrapper>
        </DescriptionWrapper>
      </TitleWrapper>
      <Content>
        {data.length > 0 ? <></> : <EmptyState type="custom" dataAid={generateDataAid(dataAid, ['emptyState'])} />}
      </Content>
    </Main>
  );
};
