import React from 'react';
import { BigidGridColumn, BigidGridColumnTypes, FlexibleCardFormatterProps } from '@bigid-ui/grid';
import { CatalogSearchGridRow, ResultsEntityType } from '../types';
import { getCatalogEntityIconByType, removeHtmlTags } from '../utils';
import { BigidFlexibleListCardHeight } from '@bigid-ui/components';
import { FooterSection } from './Card/components/Footer/FooterSection';
import { noop } from 'lodash';
import { alienEntityAdapter } from './Card/adapter';
import { FullPath } from '../components/FullPath/FullPath';

function getCatalogGridCellValue(highlight: string): BigidGridColumn<CatalogSearchGridRow>[] {
  return [
    {
      getCellValue: row => {
        const {
          title,
          data: {
            fullPath,
            type,
            datasource: { scannerType },
          },
        } = row as CatalogSearchGridRow<'catalog'>;

        const icon = getCatalogEntityIconByType(type);

        const { columns, tags, attributes, category } = row.highlights.reduce(
          (acc, { fieldName, ...rest }) => {
            if (fieldName === 'columns') acc.columns.push({ fieldName, ...rest });
            else if (fieldName === 'tags') acc.tags.push({ fieldName, ...rest });
            else if (fieldName === 'attributes') acc.attributes.push({ fieldName, ...rest });
            else if (fieldName === 'category') acc.category.push({ fieldName, ...rest });
            return acc;
          },
          { columns: [], tags: [], attributes: [], category: [] },
        );

        return {
          card: {
            isCardOutlined: true,
            cardDisplayData: {
              subtitle: {
                value: <FullPath dsType={scannerType} fullPath={fullPath} highlight={highlight} />,
              },
              title: {
                icon,
                value: removeHtmlTags(title),
                highlight,
              },
              footer: {
                value: [
                  <FooterSection
                    sectionName="columns"
                    key="columns"
                    isFirstItem
                    highlightValue={columns}
                    searchValue={highlight}
                  />,
                  <FooterSection
                    key="attributes"
                    sectionName="attributes"
                    highlightValue={attributes}
                    searchValue={highlight}
                  />,
                  <FooterSection key="tags" sectionName="tags" highlightValue={tags} searchValue={highlight} />,
                  <FooterSection
                    key="categories"
                    sectionName="category"
                    highlightValue={category}
                    searchValue={highlight}
                  />,
                ],
              },
            },
          },
        };
      },
      type: BigidGridColumnTypes.PARTITIONED_CARD,
      name: 'objectName',
      title: 'Name',
      width: 'auto',
    },
  ];
}

function getEntityTypeGridCellValue(): BigidGridColumn<CatalogSearchGridRow>[] {
  return [
    {
      name: 'name',
      title: 'Name',
      type: BigidGridColumnTypes.FLEXIBLE_CARD,
      width: 'auto',
      getCellValue: row => {
        const { contentComponent, footerComponent, icon, id, titleComponent, onTitleClick } = alienEntityAdapter(
          row as CatalogSearchGridRow<'datasource' | 'policy'>,
        );

        const config: FlexibleCardFormatterProps = {
          card: {
            onTitleClick,
            onCardClick: noop,
            cardData: {
              title: titleComponent,
              footer: footerComponent,
              avatar: {
                icon,
                name: '',
              },
              id,
            },
            content: contentComponent,
            config: {
              TITLE: {
                isCustomComponent: true,
              },
            },
            titleCustomWidth: 850,
            maxHeight: 300,
            height: BigidFlexibleListCardHeight.FLEXIBLE,
          },
        };

        return config;
      },
    },
  ];
}

export function getDataExplorerGridColumnsConfig(
  entityType: ResultsEntityType,
  highlight: string,
): BigidGridColumn<CatalogSearchGridRow>[] {
  if (entityType === 'catalog') {
    return getCatalogGridCellValue(highlight);
  }
  return getEntityTypeGridCellValue();
}
