import React, { FC, useEffect, useState } from 'react';
import { $state, $stateParams, $transitions } from '../../services/angularServices';
import { AppInfo } from './utils/CustomAppTypes';
import { CustomAppPageWrapper } from './CustomAppPageWrapper';
import { CustomAppUI } from './views/CustomAppUI/CustomAppUI';
import { getCustomAppInfo } from './utils/CustomAppUtils';
import { isEmpty } from 'lodash';
import { CONFIG } from '../../../config/common';
import { TPAPageViewsEvents, trackTPAPageView } from './customAppEventsTrackerUtils';
import { updateWorkspaceSettingsByWorkspaceId, useWorkspaces } from '../../services/workspacesService';
import { SystemEvents, systemEventsEmitter } from '../../services/systemEvents';
import { pageHeaderService } from '../../../common/services/pageHeaderService';
import { isPrivacyPortal } from '../../utilities/privacyPortalUtilities';
import { isWorkspacesEnabled } from '../../utilities/featureFlagUtils';
import { getAppPageWorkspaces } from '../../utilities/workspacesUtils';

const getAppRoute = () => {
  if ($stateParams.appRoute) {
    const queryParams = window.location.hash?.split('?')[1];
    return `#/${$stateParams.appRoute}${queryParams ? `?${queryParams}` : ''}`;
  }
  return '';
};

export const CustomAppUIPage: FC = () => {
  const [appInfo, setAppInfo] = useState<AppInfo>(null);
  const [appRoute, setAppRoute] = useState<string>('');
  const [dynamicAppRoute, setDynamicAppRoute] = useState<string>('');
  const { selectWorkspace, selectedWorkspaceId } = useWorkspaces();

  useEffect(() => {
    trackTPAPageView(TPAPageViewsEvents.TPA_UI_VIEW);
  }, []);

  useEffect(() => {
    const updateAppInfo = async () => {
      if (appInfo) return;
      const appInfoParam = $stateParams.appInfo;
      const fetchedAppInfo = appInfoParam ? appInfoParam : await getCustomAppInfo($stateParams.id, true);
      if (!fetchedAppInfo.isInteractive || fetchedAppInfo.hideUI) {
        const params = { id: fetchedAppInfo.id, fetchedAppInfo };
        $state.go(CONFIG.states.CUSTOM_APP_ACTIONS, params, { location: 'replace' });
      }
      setAppInfo(fetchedAppInfo);
    };
    const appRoute = getAppRoute();
    setAppRoute(appRoute);
    updateAppInfo();
  }, [appInfo]);

  useEffect(() => {
    const updateWorkspace = async () => {
      const appPageWorkspaces = await getAppPageWorkspaces(appInfo, $state.params.appRoute || dynamicAppRoute);
      const primaryWorkspace = appPageWorkspaces[0];
      const isWorkspaceSelected = appPageWorkspaces.includes(selectedWorkspaceId);

      if (primaryWorkspace && !isWorkspaceSelected) {
        selectWorkspace(primaryWorkspace, false);
      } else if (isWorkspacesEnabled() && primaryWorkspace) {
        updateWorkspaceSettingsByWorkspaceId({
          workspaceId: primaryWorkspace && !isWorkspaceSelected ? primaryWorkspace : selectedWorkspaceId,
          lastVisitedPageSettings: {
            name: CONFIG.states.CUSTOM_APP,
            params: {
              id: $state.params.id,
              appRoute: $state.params.appRoute || dynamicAppRoute || '',
            },
          },
        });
      }
    };

    updateWorkspace();
  }, [appInfo, dynamicAppRoute]);

  useEffect(() => {
    const deregister = $transitions.onSuccess({}, transition => {
      const newRouter = transition.params().appRoute;

      setDynamicAppRoute(newRouter);
    });

    return () => {
      deregister();
    };
  }, []);

  useEffect(() => {
    if (!appInfo) return;
    if (isPrivacyPortal(appInfo)) {
      // we ensure that the Privacy Portal occupies the full width of the layout instead of relying on the SDK.
      // This approach prevents interruptions caused by route changes, ensuring consistent header behavior
      systemEventsEmitter.emit(SystemEvents.UPDATE_LAYOUT_FULL_WIDTH_STATE, true);
      pageHeaderService.setIsHidden(true);
    }
  }, [appInfo]);

  return (
    appInfo?.isInteractive && (
      <CustomAppPageWrapper appInfo={appInfo} isReady={!isEmpty(appInfo)}>
        <CustomAppUI appInfo={appInfo} appRoute={appRoute} dynamicAppRoute={dynamicAppRoute} />
      </CustomAppPageWrapper>
    )
  );
};
