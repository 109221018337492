import { getApplicationPreference } from '../services/appPreferencesService';
import { isPermitted } from '../services/userPermissionsService';
import { CATALOG_PERMISSIONS } from '@bigid/permissions';

export const isInEarlyAdopters = () => {
  return (
    getApplicationPreference<boolean>('CUSTOMER_EA_PROGRAM_ENABLED') &&
    getApplicationPreference<boolean>('CUSTOMER_OPT_IN_EA_PROGRAM')
  );
};

export function isDataExplorerEnabled(): boolean {
  return (
    (isInEarlyAdopters() || getApplicationPreference<boolean>('DATA_EXPLORER_ENABLED')) &&
    getApplicationPreference<boolean>('METADATA_SEARCH_ENABLED') &&
    getApplicationPreference<boolean>('DATA_OVERVIEW_ENABLED') &&
    isPermitted(CATALOG_PERMISSIONS.DATA_EXPLORER_UI.name)
  );
}

export function isBigChatEnabled(): boolean {
  return isInEarlyAdopters() || getApplicationPreference<boolean>('BIGCHAT_FF_ENABLED');
}

export function isDsCollaborationEnabled(): boolean {
  return isInEarlyAdopters() || getApplicationPreference<boolean>('DS_COLLABORATION_ENABLED');
}

export function isOnboardingAssistantEnabled(): boolean {
  return isInEarlyAdopters() || getApplicationPreference<boolean>('ONBOARDING_ASSISTANT_ENABLED');
}

export function isWorkspacesEnabled(): boolean {
  return isInEarlyAdopters() || getApplicationPreference<boolean>('WORKSPACES_ENABLED');
}

export function isWorkspacesLastVisitedPageEnabled(): boolean {
  return isWorkspacesEnabled() && getApplicationPreference<boolean>('WORKSPACES_LAST_VISITED_PAGE_ENABLED');
}

export function isPrivacyPortalIntegrationEnabled(): boolean {
  return isInEarlyAdopters() || getApplicationPreference<boolean>('PRIVACY_PORTAL_INTEGRATION_ENABLED');
}

export function isAISecurityAndGovernanceEnabled(): boolean {
  return isInEarlyAdopters() || getApplicationPreference<boolean>('AI_SECURITY_AND_GOVERNANCE_ENABLED');
}

export function isLegalEntitiesEnabled(): boolean {
  return isInEarlyAdopters() || getApplicationPreference('LEGAL_ENTITIES_ENABLED_FF');
}

export function isRiskRegisterEnabled(): boolean {
  return isInEarlyAdopters() || getApplicationPreference<boolean>('RISK_REGISTER_ENABLED_FF');
}

export function isGettingStartedSecurityEnabled(): boolean {
  return getApplicationPreference<boolean>('GETTING_STARTED_SECURITY_ENABLED');
}

export function isGettingStartedEnabled(): boolean {
  return getApplicationPreference<boolean>('SHOW_GETTING_STARTED_PAGE_FF');
}
