import React, { FC } from 'react';
import { BigidAdvancedToolbarFilterTypes, BigidAdvancedToolbarFilterUnion } from '@bigid-ui/components';
import { useDashboardToolbar } from '../../../components/ExecutiveDashboard/hooks/useDashboardToolbar';
import { ExecutiveDashboard, DashboardType } from '../../../components/ExecutiveDashboard';
import { useLocalTranslation } from '../translations';
import { WidgetsContainer } from './Widgets/WidgetsContainer';

export const COMPLIANCE_DASHBOARD_ID = 'complianceDashboard';

enum FilterToolbarTitles {
  RISK_TYPE = 'riskType',
  DATA_SOURCE = 'dataSource',
  ENVIRONMENT = 'environment',
  FRAMEWORKS = 'frameworks',
}

export const ComplianceDashboard: FC = () => {
  const { t } = useLocalTranslation('compliance.dashboard.toolbarTitles');
  const toolbarFilters: BigidAdvancedToolbarFilterUnion[] = [
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: FilterToolbarTitles.RISK_TYPE,
      field: FilterToolbarTitles.RISK_TYPE,
      title: t(FilterToolbarTitles.RISK_TYPE),
      parentId: FilterToolbarTitles.RISK_TYPE,
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: FilterToolbarTitles.DATA_SOURCE,
      field: FilterToolbarTitles.DATA_SOURCE,
      title: t(FilterToolbarTitles.DATA_SOURCE),
      parentId: FilterToolbarTitles.DATA_SOURCE,
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: FilterToolbarTitles.ENVIRONMENT,
      field: FilterToolbarTitles.ENVIRONMENT,
      title: t(FilterToolbarTitles.ENVIRONMENT),
      parentId: FilterToolbarTitles.ENVIRONMENT,
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => [],
    },
    {
      type: BigidAdvancedToolbarFilterTypes.DROPDOWN,
      id: FilterToolbarTitles.FRAMEWORKS,
      field: FilterToolbarTitles.FRAMEWORKS,
      title: t(FilterToolbarTitles.FRAMEWORKS),
      parentId: FilterToolbarTitles.FRAMEWORKS,
      operator: 'in',
      options: [],
      asyncOptionsFetch: async () => [],
    },
  ];

  const {
    activeFilters,
    externalAppliedFilters,
    actions: { updateFilters, updateExternalFilters },
  } = useDashboardToolbar(toolbarFilters, COMPLIANCE_DASHBOARD_ID);

  return (
    <ExecutiveDashboard
      dashboardId={COMPLIANCE_DASHBOARD_ID}
      dashboardType={DashboardType.COMPLIANCE}
      toolbarFilters={toolbarFilters}
      activeFilters={activeFilters}
      onFiltersChange={updateFilters}
      updateExternalFilters={updateExternalFilters}
      externalAppliedFilters={externalAppliedFilters}
      isSavedFiltersTabsDisplayed={true}
    >
      <WidgetsContainer activeFilters={activeFilters} />
    </ExecutiveDashboard>
  );
};
