import { httpService } from '../../../services/httpService';
import { RelationGridRow } from './types';
import { BigidGridQueryComponents, BigidGridWithToolbarProps } from '@bigid-ui/grid';
import { uniqBy } from 'lodash';
import { DataSourcesResponse } from '../../DataSources/DataSourceConnections/DataSourceConnectionTypes';
import { queryService } from '../../../services/queryService';
import { DataCatalogObjectType, DistinctAttribute } from '../DataCatalogService';
import { getManualTagsByTags } from './utils';

export const DATA_SOURCES_FILTER_QUERY = 'skip=0&limit=100&requireTotalCount=false';

export async function getRelations(queryComponents: BigidGridQueryComponents, fullyQualifiedName: string) {
  const hasStatusFilter = queryComponents.filter?.some(filter => filter.field === 'state');
  const gridConfigQuery = queryService.getGridConfigQuery({
    ...queryComponents,
    filter: hasStatusFilter ? queryComponents.filter : [...queryComponents.filter],
    sort: [...queryComponents.sort],
  });
  const {
    data: { data: result },
  } = await httpService.fetch(`data-catalog/relations/${encodeURIComponent(fullyQualifiedName)}?${gridConfigQuery}`);

  return {
    data: result.map(
      (relation: RelationGridRow) => ((relation.manualTags = getManualTagsByTags(relation.tags)), relation),
    ),
    totalCount: result.length,
  };
}

export async function addRelations(fullyQualifiedName: string, dataSourceName: string, selectedObjects: string[]) {
  const addRelationBody = {
    label: 'trainedBy',
    toObjectFqn: fullyQualifiedName,
    source: dataSourceName,
    fromObjectsFqn: selectedObjects,
  };
  return httpService.post(`data-catalog/relations`, addRelationBody);
}

export async function deleteRelations(fullyQualifiedName: string, dataSourceName: string, selectedObjects: string[]) {
  const deleteRelationBody = {
    label: 'trainedBy',
    toObjectFqn: fullyQualifiedName,
    source: dataSourceName,
    fromObjectsFqn: selectedObjects,
  };
  return httpService.delete(`data-catalog/relations`, deleteRelationBody);
}

export async function getCandidates(fullyQualifiedName: string) {
  const {
    data: { data: result },
  } = await httpService.fetch(`data-catalog/relations/${encodeURIComponent(fullyQualifiedName)}/candidates`);
  return result;
}

export async function getInitialFilters() {
  const types = await fetchTypes();
  const dataSources = await fetchDsConnections();
  const attributes = await fetchAttributes();
  const initialActivityQueueFilters: BigidGridWithToolbarProps<RelationGridRow>['filterToolbarConfig'] = {
    filters: [
      {
        title: 'Object type',
        field: 'extendedObjectType',
        operator: 'in',
        options: types,
        isSelected: false,
        value: [],
        isSearchAsync: true,
        loadSearchOptions: fetchTypes,
        listWidth: 400,
      },
      {
        title: 'Data source',
        field: 'source',
        operator: 'in',
        options: dataSources,
        isSelected: false,
        value: [],
        isSearchAsync: true,
        loadSearchOptions: fetchDsConnections,
        listWidth: 400,
      },
      {
        title: 'Attributes',
        field: 'attributes',
        operator: 'in',
        options: attributes,
        isSelected: false,
        value: [],
        isSearchAsync: true,
        loadSearchOptions: fetchAttributes,
        listWidth: 400,
      },
    ],
    searchConfig: {
      searchFilterKeys: ['objectName'],
      initialValue: '',
      placeholder: '',
      operator: 'textSearch',
      autoSubmit: true,
    },
  };

  return initialActivityQueueFilters;
}

export const fetchDsConnections = async () => {
  const dataSources = (
    await httpService.fetch<{ data: DataSourcesResponse }>(
      `ds-connections?${DATA_SOURCES_FILTER_QUERY}&fields=["name"]`,
    )
  ).data.data.ds_connections;

  return uniqBy(
    dataSources.map(dataSource => ({
      label: dataSource.name,
      value: dataSource.name,
      isSelected: false,
    })),
    'value',
  );
};

export const fetchAttributes = async () => {
  const attributes = await httpService
    .fetch<{ results: DistinctAttribute[] }>(`data-catalog/distinct-values/attribute`)
    .then(({ data }) => data.results.filter(({ value }) => value));
  return uniqBy(
    attributes.map(attribute => ({
      label: attribute.value,
      value: attribute.value,
      isSelected: false,
    })),
    'value',
  );
};

export const fetchTypes = async () => {
  const types: DataCatalogObjectType[] = [DataCatalogObjectType.DATASET, DataCatalogObjectType.VECTOR];
  return uniqBy(
    types.map(type => ({
      label: type,
      value: type,
      isSelected: false,
    })),
    'value',
  );
};
