import './policies.component.scss';
import { module } from 'angular';

const app = module('app');
import template from './policies.component.html';
import { isPermitted } from '../../react/services/userPermissionsService';
import { POLICIES_PERMISSIONS, APPLICATIONS_PERMISSIONS } from '@bigid/permissions';
import { httpService } from '../../react/services/httpService';
import { differenceWith, uniqBy } from 'lodash';
import { getOptimizedControls } from './Widgets/utils';
import { getUsersQuery } from '../../react/utilities/systemUsersUtils';
import '../../react/components/ActionOnTrigger/ActionOnTrigger';
import '../classifiers/ClassifiersFilterDropdown/ClassifiersFilterDropdown';
import './Widgets/MappedControls/MappedControlsWidget';
import { showDeleteACEntityDialog } from '../../react/views/ActionCenter/DeleteEntityDialog/DeleteEntityDialogContent';
import { EntityType } from '../../react/views/ActionCenter/ActionWorkflow/actionWorkflowTypes';
import { getApplicationPreference } from '../../react/services/appPreferencesService';
import { dateTimeService } from '@bigid-ui/i18n';
import { publicUrls } from '../../react/config/publicUrls';
import { getUniqueCustomApps } from '../../react/views/ApplicationsManagement/Tabs/InstalledApps';
import { getAppInstances, getNormalizedAppNameForLabel } from '../../react/views/CustomApp/utils/CustomAppUtils';
import { getCommandToDisplayName } from '../../react/views/ActionableInsights/CaseSidePanel/CaseReport/CaseReportWidgets/CaseActionWidget/CaseActionsWidget';

app.component('policies', {
  template,
  controller: function (
    $document,
    $rootScope,
    $state,
    $timeout,
    $translate,
    DeleteConfirmation,
    notificationService,
    policiesService,
    systemUsersService,
    $scope,
  ) {
    'ngInject';

    const STATUS_MAP = {
      false: 'UNVIOLATED',
      true: 'VIOLATED',
    };

    const TRANSLATION_REQUIRED = [
      'POLICIES',
      'POLICIES:TEST:SECTION:ACTIONS:STATUS:PASSED',
      'POLICIES:TEST:SECTION:ACTIONS:STATUS:FAILED',
      'POLICIES:TEST:SECTION:ACTIONS:STATUS:REVIEWED_AND_ADDRESSED',
      'POLICIES:FORM:POLICY_TYPE:ACCESS_GOVERNANCE',
      'POLICIES:FORM:POLICY_TYPE:COMPLEX_ATTRIBUTE',
      'POLICIES:FORM:POLICY_TYPE:PRIVACY',
      'POLICIES:FORM:POLICY_TYPE:CATALOG',
      'POLICIES:FORM:POLICY_SEVERITY:LOW',
      'POLICIES:FORM:POLICY_SEVERITY:MEDIUM',
      'POLICIES:FORM:POLICY_SEVERITY:HIGH',
      'POLICIES:FORM:POLICY_SEVERITY:CRITICAL',
    ];

    const POLICIES_DATA_LIST_ID = 'policiesDataList';
    const POLICIES_CONTENT_BODY_ID = 'policiesContentBody';
    const POLICIES_DATA_LIST_ITEM_ID = 'policy';

    const DEFAULT_POLICY_TYPE = 'privacy';

    const policyTypeOptions = {
      privacy: '',
      access_governance: '',
      catalog: '',
    };

    const severityLevelOptions = {
      critical: '',
      high: '',
      medium: '',
      low: '',
    };

    if (this.isPoliciesComplexAttributeEnabled) {
      // policyTypeOptions[complexAttribute] = '';  //:TODO: uncomment this
    }

    const statusValues = {
      failed: '',
      passed: '',
      reviewedAndAddressed: '',
    };

    const dataListContainer = $document[0].getElementById(POLICIES_DATA_LIST_ID);
    const contentBodyContainer = $document[0].getElementById(POLICIES_CONTENT_BODY_ID);

    let isFormDirty = false;

    this.policies = [];
    this.systemUsers = [];
    this.policiesOwners = [];
    this.mappedControls = [];
    this.isMappedCotrolsButtonEnabled = true;
    this.policy = {};
    this.policyForm = {};
    this.userQuery = '';
    this.defaultSeverity = 'medium';
    this.editModeOn = false;
    this.createModeOn = false;
    this.filter = null;
    this.isDataLoading = false;
    this.policyTypeOptions = [];
    this.severityLevelOptions = [];
    this.policyTypeInventoryLinkable = ['privacy'];
    this.policyTypeCatalogLinkable = ['access_governance', 'catalog'];
    this.policyTypeComplexAttribute = ['complexAttribute'];
    this.apps = [];
    this.appsObjects = [];
    this.instances = [];
    this.selectedApps = [];
    this.selectedInstances = [];
    this.actions = [];
    this.selectedActions = [];
    this.presets = [];
    this.selectedPresets = [];
    this.allActionsPresets = {};
    this.tpaAdditionalParams = '';
    this.policyRegisteredActionsCommands = new Set();

    this.isDeletePermitted = isPermitted(POLICIES_PERMISSIONS.DELETE.name);
    this.isTestPermitted = isPermitted(POLICIES_PERMISSIONS.TEST_POLICY.name);
    this.isEditPermitted = isPermitted(POLICIES_PERMISSIONS.EDIT.name);
    this.isCreatePermitted = isPermitted(POLICIES_PERMISSIONS.ADD_POLICY.name);
    this.isAppActionAndQueryPermitted = isPermitted(APPLICATIONS_PERMISSIONS.READ_TPA_CUSTOM_APPS.name);
    this.isPoliciesComplexAttributeEnabled = getApplicationPreference('POLICIES_COMPLEX_ATTRIBUTE');
    this.isPolicyActionsEnabled = getApplicationPreference('DSPM_POLICY_ACTIONS_ENABLED');
    this.isFrameworkComplianceEnabled = getApplicationPreference('COMPLIANCE_FRAMEWORKS_ENABLED');
    this.isActionableInsightsEnabled = getApplicationPreference('ACTIONABLE_INSIGHTS_ENABLED');
    this.useDisplayNameForPolicy = getApplicationPreference('USE_DISPLAY_NAME_FOR_POLICY_FF');
    this.isTpaMultipleDeploymentsEnabled = getApplicationPreference('TPA_MULTIPLE_DEPLOYMENTS_ENABLED');
    this.useRemidiationSteps = getApplicationPreference('USE_REMEDIATION_STEPS_FF');

    this.filterItem = {
      options: [],
      value: [],
      onSelect: options => {
        isFormDirty = true;
        $rootScope.$apply(() => {
          this.filterItem.value = options;
        });
      },
    };

    this.onUserQueryChange = () => {
      this.scrollToTop();
    };

    this.scrollToTop = () => void 0;
    this.onScrollBarReady = ({ ps, scrollToTop }) => {
      this.scrollToTop = () => {
        $timeout(
          () => {
            ps.update();
            scrollToTop();
          },
          10,
          false,
        );
      };
    };
    /* API section --start */

    const fetchPolicies = (scrollTo = '', entityToProceed = null) => {
      this.onDataLoading();

      policiesService.getPolicies().then(
        result => {
          let policies = result.data;

          if (this.filter !== null) {
            const { type, isFailed } = this.filter;

            policies = policies.filter(policy => {
              let isGood = true;

              if (typeof type != 'undefined') {
                isGood = isGood && policy.type === type;
              }

              if (typeof isFailed != 'undefined') {
                isGood = isGood && policy.status === STATUS_MAP[isFailed.toString()];
              }

              return isGood;
            });
          }

          this.policies = policies.sort(alphaSort('name'));

          this.onDataLoaded();

          if (entityToProceed !== null) {
            if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
              onCreatePolicy();
            } else {
              onEditPolicy(entityToProceed);
            }
          } else {
            if (this.editModeOn) {
              const index = this.policies.findIndex(policy => policy.id === this.policy.id);

              if (index !== -1) {
                onEditPolicy(this.policies[index]);
              }
            }
          }

          $timeout(() => {
            if (!dataListContainer) return;
            switch (scrollTo) {
              case 'top':
                dataListContainer.scrollTop = 0;
                break;
              case 'bottom':
                dataListContainer.scrollTop = dataListContainer.scrollHeight;
                break;
              default:
                if (entityToProceed !== null) {
                  if (Object.keys(entityToProceed).length === 0 && entityToProceed.constructor === Object) {
                    dataListContainer.scrollTop = 0;
                  } else {
                    const index = this.policies.findIndex(policy => policy.id === entityToProceed.id),
                      dataListItem = $document[0].getElementById(`POLICIES_DATA_LIST_ITEM_ID-${index}`);

                    if (dataListItem !== null && index !== -1) {
                      dataListContainer.scrollTop = index * dataListItem.offsetHeight;
                    }
                  }
                }
            }
          }, 0);
        },
        () => {
          this.onDataLoaded();

          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const createPolicy = (data, entityToProceed = null) => {
      policiesService.createPolicy(data).then(
        response => {
          $translate('API:MESSAGE:COMMON_POST_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true, policyId: response.data.ruleId });
          } else {
            this.editModeOn = true;
            this.createModeOn = false;

            this.policy.id = response.data.ruleId;

            isFormDirty = false;

            this.policyForm.$submitted = false;

            if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

            fetchPolicies('', entityToProceed);
          }
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const updatePolicy = (id, data, entityToProceed = null) => {
      policiesService.updatePolicy(id, data).then(
        () => {
          this.policy = {
            ...this.policy,
            actions: [...this.selectedActions],
            presets: this.selectedPresets,
            tpaAdditionalParams: this.tpaAdditionalParams,
            apps: [
              ...(this.isTpaMultipleDeploymentsEnabled && this.selectedInstances?.length > 0
                ? this.selectedInstances
                : this.selectedApps),
            ],
          };
          $translate('API:MESSAGE:COMMON_PUT_SUCCESS').then(translation => {
            notificationService.success(translation);
          });

          if (this.formOnly) {
            this.onFormSubmitted({ isUpdated: true });
          } else {
            if (contentBodyContainer !== null) contentBodyContainer.scrollTop = 0;

            this.policyForm.$submitted = false;

            fetchPolicies('', entityToProceed);

            isFormDirty = false;
          }
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    const testPolicy = data => {
      const {
        complianceRuleCalc: { bigidQuery, maxFindings },
        type,
      } = data;

      policiesService.testPolicy({ bigidQuery, maxFindings, type }).then(
        result => {
          $translate('POLICIES:API:MESSAGE:TEST', { entityName: data.name }).then(translation => {
            notificationService.success(translation);
          });

          if (typeof this.policy.findings == 'undefined' || this.policy.findings === null) {
            this.policy.findings = {};
          }

          this.policy.findings.calcDate = getTimeStringFromDate(result.data.calcDate);
          this.policy.findings.findingsAmt = result.data.findingsAmt;
          this.policy.findings.violated = result.data.violated;

          this.policyForm.$submitted = false;
        },
        () => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        },
      );
    };

    /* API section --end */

    /* methods section --start */

    const alphaSort = prop => (a, b) => {
      const operandA = a[prop].toLowerCase(),
        operandB = b[prop].toLowerCase();

      if (operandA < operandB) {
        return -1;
      } else if (operandA > operandB) {
        return 1;
      } else {
        return 0;
      }
    };

    const goToTask = taskId => {
      $state.go('taskList', { taskId });
    };

    const getStatusValue = policy => {
      let statusValue = '';

      switch (policy.status) {
        case 'VIOLATED':
          statusValue = statusValues.failed;
          break;
        case 'UNVIOLATED':
          statusValue = statusValues.passed;
          break;
        case 'POSTPONED':
          statusValue = statusValues.reviewedAndAddressed;
          break;
      }

      return statusValue;
    };

    const getTimeStringFromDate = date => dateTimeService.formatDate(date);

    const resetFormState = () => {
      if (
        typeof this.policyForm != 'undefined' &&
        typeof this.policyForm.$setPristine == 'function' &&
        typeof this.policyForm.$setUntouched == 'function'
      ) {
        this.policyForm.$setPristine();
        this.policyForm.$setUntouched();
      } else {
        this.policyForm = {};
      }

      this.policyForm.$submitted = false;

      isFormDirty = false;
    };

    this.resetAppsActions = () => {
      this.selectedApps = [];
      this.selectedInstances = [];
      this.selectedActions = [];
      this.selectedPresets = [];
      this.tpaAdditionalParams = '';
    };

    const isAppsDataHasChanged = () => {
      const originalApps = this.policy?.apps || [];
      const originalActions = this.policy?.actions || [];
      const originalPresets = this.policy?.presets || [];
      const originalCustomQuery = this.policy?.tpaAdditionalParams || '';
      const hasAppsChanged =
        this.selectedApps.length !== originalApps.length ||
        differenceWith(this.selectedApps, originalApps, isAppActionEqual).length !== 0;
      const hasActionsChanged =
        this.selectedApps.length !== originalApps.length ||
        differenceWith(this.selectedActions, originalActions, isAppActionEqual).length !== 0;
      const hasPresetsChanged =
        this.selectedPresets.length !== originalPresets.length ||
        differenceWith(this.selectedPresets, originalPresets, isAppActionEqual).length !== 0;
      const hasCustomQueryChanged = this.tpaAdditionalParams !== originalCustomQuery;

      return hasAppsChanged || hasActionsChanged || hasPresetsChanged || hasCustomQueryChanged;
    };

    const isAppActionEqual = (a, b) => a.value === b.value && a.label === b.label;

    const updateActionsOnEntityChange = entity => {
      const policyAppId = entity.apps?.[0]?.value;
      const policyActionId = entity.actions?.[0]?.value;
      loadInstancesData(policyAppId);
      return loadActionsData(policyAppId, policyActionId).then(() => {
        onEditPolicy(entity);
      });
    };

    const loadActionsData = (selectedAppId, selectedActionId) => {
      if (!selectedAppId) {
        return Promise.resolve();
      }
      return httpService
        .fetch(`tpa/${selectedAppId}/actions`, {
          limit: 1000,
          skip: 0,
          sort_by: 'action_name',
          is_asc: true,
        })
        .then(({ data }) => {
          this.actions = data
            .filter(({ execution_type }) => execution_type === 'OneTimeRun')
            .map(action => mapPresets(action));
          this.presets = this.allActionsPresets[selectedActionId];
        });
    };

    const loadInstancesData = selectedAppId => {
      if (!selectedAppId) {
        return;
      }
      const instancesObjects = getAppInstances(
        this.appsObjects.find(app => app._id === selectedAppId),
        this.appsObjects,
      );
      this.instances = instancesObjects.map(({ tpa_name, friendly_name, _id }) => ({
        label: friendly_name ?? tpa_name,
        value: _id,
      }));
      if (this.instances.length <= 1) {
        return loadActionsData(selectedAppId);
      }
    };

    const mapPresets = action => {
      this.allActionsPresets[action._id] = action.presets.map(({ name: label, _id: value }) => ({ label, value }));
      return {
        label: action.action_name,
        value: action._id,
      };
    };

    const updatePresetData = actionId => {
      if (!actionId) {
        return Promise.resolve();
      }
      this.presets = this.allActionsPresets[actionId];
    };

    this.attributesDropdownSettings = {
      showCheckAll: false,
      showUncheckAll: false,
      checkBoxes: true,
      selectedToTop: false,
      displayProp: 'label',
      idProperty: 'value',
      enableSearch: true,
      smartButtonMaxItems: 2,
      smartButtonTextConverter: function (itemText) {
        return itemText;
      },
      template: '<span title="{{option.label}}">{{option.label}}</span>',
    };

    this.attributesDropdownSingleSettings = {
      ...this.attributesDropdownSettings,
      selectionLimit: 1,
    };

    this.onApplicationSelectionChanged = () => {
      this.instances = [];
      this.selectedInstances = [];
      this.actions = [];
      this.selectedActions = [];
      this.presets = [];
      this.selectedPresets = [];

      if (this.selectedApps.length !== 0) {
        const { value: selectedAppId } = this.selectedApps[0];
        return this.isTpaMultipleDeploymentsEnabled
          ? loadInstancesData(selectedAppId)
          : loadActionsData(selectedAppId, null);
      }
    };

    this.onInstanceSelectionChanged = () => {
      this.actions = [];
      this.selectedActions = [];
      this.presets = [];
      this.selectedPresets = [];

      if (this.selectedInstances.length !== 0) {
        const { value: selectedInstanceId } = this.selectedInstances[0];
        return loadActionsData(selectedInstanceId, null);
      }
    };

    this.onActionSelectionChanged = () => {
      this.presets = [];
      this.selectedPresets = [];
      if (this.selectedActions.length !== 0) {
        const { value: selectedActionId } = this.selectedActions[0];
        updatePresetData(selectedActionId);
      }
    };

    const resetListSelection = (except = '') => {
      for (let i = 0, len = this.policies.length; i < len; i++) {
        if (this.policies[i].id === except) {
          continue;
        }
        this.policies[i]['selected'] = false;
      }
    };

    const checkPolicyActions = (name = '') => {
      $rootScope.$applyAsync(() => {
        policiesService
          .getPolicyActions(name)
          .then(
            ({
              data: {
                data: { allActions, registeredActions },
              },
            }) => {
              this.policyRegisteredActionsCommands = new Set(registeredActions.map(({ command }) => command));
              this.filterItem.options = allActions.map(action => ({
                ...action,
                id: action.command,
                value: action.command,
                displayValue: getCommandToDisplayName(action.command),
              }));
              this.filterItem.value = registeredActions
                .filter(action => action.enabled === true)
                .map(({ command }) => ({
                  id: command,
                  value: command,
                  displayValue: getCommandToDisplayName(command),
                }));
            },
          )
          .catch(e => {
            this.filterItem.options = [];
            this.filterItem.value = [];
            notificationService.error('failed to load actions for the policy');
            console.error(e);
          });
      });
    };

    this.fetchMappedControls = (policyFqdn = '') => {
      $rootScope.$applyAsync(() => {
        policiesService
          .getMappedControls(policyFqdn || this.policy.fqdn)
          .then(({ data: { data } }) => {
            this.mappedControls = getOptimizedControls(data);
          })
          .catch(e => {
            console.error(e);
          });
      });
    };

    const resetMappedControlsOnPolicyCreate = () => {
      this.mappedControls = [];
      this.isMappedCotrolsButtonEnabled = false;
    };

    const changeMappedControlsButtonVisibility = value => {
      this.isMappedCotrolsButtonEnabled = value;
    };

    const onCreatePolicyActions = (name = '') => {
      const selectedActions = new Set(this.filterItem.value.map(action => action.value));
      const filteredActions = this.filterItem.options
        .filter(({ command }) => this.policyRegisteredActionsCommands.has(command) || selectedActions.has(command))
        .map(action => {
          return {
            actionName: action.actionName,
            command: action.command,
            subType: action.subType,
            enabled: selectedActions.has(action.command),
          };
        });
      const data = {
        policyName: name,
        actions: filteredActions,
      };
      $rootScope.$applyAsync(() => {
        policiesService
          .createPolicyActions(data)
          .then()
          .catch(e => {
            notificationService.error('failed to load actions for the policy');
            console.error(e);
          });
      });
    };

    const setListSelection = id => {
      for (let i = 0, len = this.policies.length; i < len; i++) {
        if (this.policies[i].id === id) {
          this.isFrameworkComplianceEnabled && this.fetchMappedControls(this.policies[i].fqdn);
          this.policies[i]['selected'] = true;
          const currentPolicy = this.policies[i];
          this.isPolicyActionsEnabled && checkPolicyActions(currentPolicy?.name);
          break;
        }
      }
    };

    const onSubmitPolicy = (entityToProceed = null) => {
      if (!this.policyForm.$valid) return; //:TODO: combine with ActionTrigger component validation state
      const payload = angular.copy(this.policy);
      if (typeof payload.selected != 'undefined') delete payload.selected;
      switch (true) {
        case this.createModeOn:
          payload['is_enabled'] = true;
          payload.apps =
            this.isTpaMultipleDeploymentsEnabled && this.selectedInstances?.length > 0
              ? this.selectedInstances
              : this.selectedApps;
          payload.actions = this.selectedActions;
          payload.presets = this.selectedPresets;
          payload.tpaAdditionalParams = this.tpaAdditionalParams;
          if (!payload.name && this.useDisplayNameForPolicy) {
            payload.name = payload.displayName;
          }
          this.isPolicyActionsEnabled && onCreatePolicyActions(payload.name);
          this.isFrameworkComplianceEnabled && changeMappedControlsButtonVisibility(true);
          createPolicy(payload, entityToProceed);
          break;
        case this.editModeOn:
          if (isAppsDataHasChanged()) {
            payload.apps = [
              ...(this.isTpaMultipleDeploymentsEnabled && this.selectedInstances?.length > 0
                ? this.selectedInstances
                : this.selectedApps),
            ];
            payload.actions = [...this.selectedActions];
            payload.presets = [...this.selectedPresets];
            payload.tpaAdditionalParams = this.tpaAdditionalParams;
          }
          this.isPolicyActionsEnabled && onCreatePolicyActions(payload.name);
          updatePolicy(payload.id, payload, entityToProceed);
          break;
      }
    };

    const onTestPolicy = data => {
      if (!this.policyForm.$valid) return;

      testPolicy(data);
    };

    const onCancelPolicy = () => {
      this.policy = {};

      resetFormState();
    };

    const verifySelectedAppIsOnOptions = () => {
      this.apps = [
        ...this.apps.filter(
          app =>
            getNormalizedAppNameForLabel(this.appsObjects, app.value, app.label) !==
            getNormalizedAppNameForLabel(this.appsObjects, this.selectedApps[0]?.value, this.selectedApps[0]?.label),
        ),
        ...this.selectedApps.map(({ value, label }) => {
          return {
            value,
            label: getNormalizedAppNameForLabel(this.appsObjects, value, label),
          };
        }),
      ];
    };

    const onCreatePolicy = () => {
      resetListSelection();
      this.resetAppsActions();

      this.policy = {};

      this.createModeOn = true;
      this.editModeOn = false;
      this.filterItem.value = [];
      resetFormState();
      this.policy.severity = this.defaultSeverity;
    };

    const onEditPolicy = policy => {
      resetListSelection();
      setListSelection(policy.id);

      const apps = policy.apps || [];
      const actions = policy.actions || [];
      const presets = policy.presets || [];
      const tpaAdditionalParams = policy.tpaAdditionalParams || '';

      this.isDataLoading = true;

      getPolicyOwner(policy.owner)
        .then(user => {
          const updatedOwners = [...this.policiesOwners, { id: this.policiesOwners.length + 1, name: user.name }];
          this.policiesOwners = uniqBy(updatedOwners, 'name');
        })
        .catch(e => {
          notificationService.error('failed to load policy owners');
          console.error(e);
        })
        .finally(() => {
          this.policy = angular.copy(policy);
          this.selectedApps = [...apps];
          if (this.isTpaMultipleDeploymentsEnabled) {
            verifySelectedAppIsOnOptions();
          }
          this.selectedInstances = [...apps];
          this.selectedActions = [...actions];
          this.selectedPresets = [...presets];
          this.tpaAdditionalParams = tpaAdditionalParams;
          if (this.useDisplayNameForPolicy) {
            this.policy.displayName = policy.displayName ?? policy.name;
          }

          this.editModeOn = true;
          this.createModeOn = false;

          if (typeof this.policy.findings != 'undefined' && this.policy.findings !== null) {
            this.policy.findings.calcDate = getTimeStringFromDate(this.policy.findings.calcDate);
          }

          if (typeof this.policy.action != 'undefined' && this.policy.action !== null) {
            this.policy.action.postponeToDate = getTimeStringFromDate(this.policy.action.postponeToDate);
          }

          if (typeof this.policy.status != 'undefined' && this.policy.status !== null) {
            this.policy.statusValue = getStatusValue(this.policy);
          }

          this.policy.remediation_steps = policy.remediation_steps ?? '';

          // for backward compatibility
          if (typeof this.policy.type == 'undefined') {
            this.policy.type = DEFAULT_POLICY_TYPE;
          }

          // for backward compatibility
          if (typeof this.policy.taskSettings == 'undefined') {
            this.policy.taskSettings = {
              includeLinkToInventory: false,
              includeLinkToCatalog: false,
              includeComplexAttribute: false,
              includeObjectsReport: false,
            };
          }

          resetFormState();
          this.isDataLoading = false;
        });
    };

    function getPolicyOwner(owner) {
      const query = getUsersQuery({
        maxUsers: 1,
        filter: [
          {
            field: 'name',
            operator: 'equal',
            value: owner,
          },
        ],
      });

      return systemUsersService.getAllSystemUsersByQuery(query).then(({ data: { users: [owner] = [] } }) => owner);
    }

    const onUserLeavesForm = async (resolveCallback, rejectCallback) => {
      const closeButtonText = await $translate('BUTTONS:NO');
      const actionButtonText = await $translate('BUTTONS:YES');
      const headerText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_HEADER');
      const bodyText = await $translate('APP:COMMON:LEAVE_CONFIRMATION_TEXT');

      const modalOptions = { closeButtonText, actionButtonText, headerText, bodyText };

      DeleteConfirmation.showModal({}, modalOptions).then(resolveCallback, rejectCallback);
    };

    /* methods section --end */

    this.$onInit = () => {
      $translate(TRANSLATION_REQUIRED).then(translations => {
        statusValues.failed = translations['POLICIES:TEST:SECTION:ACTIONS:STATUS:FAILED'];
        statusValues.passed = translations['POLICIES:TEST:SECTION:ACTIONS:STATUS:PASSED'];
        statusValues.reviewedAndAddressed = translations['POLICIES:TEST:SECTION:ACTIONS:STATUS:REVIEWED_AND_ADDRESSED'];

        policyTypeOptions['privacy'] = translations['POLICIES:FORM:POLICY_TYPE:PRIVACY'];
        policyTypeOptions['access_governance'] = translations['POLICIES:FORM:POLICY_TYPE:ACCESS_GOVERNANCE'];
        policyTypeOptions['catalog'] = translations['POLICIES:FORM:POLICY_TYPE:CATALOG'];

        severityLevelOptions['low'] = translations['POLICIES:FORM:POLICY_SEVERITY:LOW'];
        severityLevelOptions['medium'] = translations['POLICIES:FORM:POLICY_SEVERITY:MEDIUM'];
        severityLevelOptions['high'] = translations['POLICIES:FORM:POLICY_SEVERITY:HIGH'];
        severityLevelOptions['critical'] = translations['POLICIES:FORM:POLICY_SEVERITY:CRITICAL'];

        if (this.isPoliciesComplexAttributeEnabled) {
          // policyTypeOptions['complexAttribute'] = translations['POLICIES:FORM:POLICY_TYPE:COMPLEX_ATTRIBUTE']; //:TODO: uncomment this
        }
        this.policyTypeOptions = Object.keys(policyTypeOptions).reduce(
          (aggregator, type) => [...aggregator, { name: policyTypeOptions[type], value: type }],
          [],
        );

        this.severityLevelOptions = Object.entries(severityLevelOptions).map(([value, name]) => ({ name, value }));

        if (this.formOnly) {
          this.isDataLoading = true;

          if (typeof this.formData != 'undefined') {
            this.editModeOn = true;
            this.createModeOn = false;
          } else {
            this.editModeOn = false;
            this.createModeOn = true;
          }
          const gridConfigQuery = getUsersQuery({});
          const promises = [systemUsersService.getAllSystemUsersByQuery(gridConfigQuery), httpService.fetch('tpa')];
          Promise.all(promises)
            .then(
              ([
                {
                  data: { users, totalCount },
                },
                { data: apps },
              ]) => {
                this.policiesOwners = users.reduce(
                  (names, user) => [...names, { id: names.length + 1, name: user.name }],
                  [],
                );
                this.usersTotalCount = totalCount;
                this.appsObjects = apps;
                apps = this.isTpaMultipleDeploymentsEnabled ? getUniqueCustomApps(apps) : apps;
                this.apps = apps.map(({ tpa_name: label, _id: value }) => ({ value, label }));

                switch (true) {
                  case this.createModeOn:
                    if (typeof this.initialData != 'undefined') {
                      this.policy = Object.assign({}, this.initialData);
                    }
                    break;
                  case this.editModeOn:
                    const appId = this.formData.apps?.[0]?.value;
                    const actionId = this.formData.actions?.[0]?.value;
                    return loadActionsData(appId, actionId).then(() => {
                      this.policy = Object.assign({}, this.formData);

                      if (typeof this.policy.findings != 'undefined' && this.policy.findings !== null) {
                        this.policy.findings.calcDate = getTimeStringFromDate(this.policy.findings.calcDate);
                      }

                      if (typeof this.policy.action != 'undefined' && this.policy.action !== null) {
                        this.policy.action.postponeToDate = getTimeStringFromDate(this.policy.action.postponeToDate);
                      }

                      if (typeof this.policy.status != 'undefined' && this.policy.status !== null) {
                        this.policy.statusValue = getStatusValue(this.policy);
                      }
                    });
                    break;
                }

                this.isDataLoading = false;
              },
            )
            .catch(() => {
              this.isDataLoading = false;

              $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                notificationService.error(translation);
              });
            });
        } else {
          $rootScope.$broadcast('changePage', translations['POLICIES'], false);

          this.onDataLoading();
          const gridConfigQuery = getUsersQuery({
            maxUsers: 20,
          });
          const promises = [
            policiesService.getPolicies(),
            systemUsersService.getAllSystemUsersByQuery(gridConfigQuery),
            httpService.fetch('tpa'),
          ];
          Promise.all(promises)
            .then(
              ([
                { data: policies },
                {
                  data: { users, totalCount },
                },
                { data: apps },
              ]) => {
                this.appsObjects = apps;
                apps = this.isTpaMultipleDeploymentsEnabled ? getUniqueCustomApps(apps) : apps;
                this.apps = apps.map(({ tpa_name: label, _id: value }) => ({
                  value,
                  label: this.isTpaMultipleDeploymentsEnabled
                    ? getNormalizedAppNameForLabel(apps, value, label)
                    : label,
                }));
                this.policiesOwners = users.reduce(
                  (names, user) => [...names, { id: names.length + 1, name: user.name }],
                  [],
                );
                this.usersTotalCount = totalCount;

                if (this.filter !== null) {
                  const { type, isFailed } = this.filter;

                  policies = policies.filter(policy => {
                    let isGood = true;

                    if (typeof type != 'undefined') {
                      isGood = isGood && policy.type === type;
                    }

                    if (typeof isFailed != 'undefined') {
                      isGood = isGood && policy.status === STATUS_MAP[isFailed.toString()];
                    }

                    return isGood;
                  });
                }

                this.policies = policies.sort(alphaSort('name'));

                if (this.policies.length > 0) {
                  let policyToEditIndex = -1;

                  if (
                    (typeof this.policyId != 'undefined' && this.policyId !== null) ||
                    (typeof this.policyName != 'undefined' && this.policyName !== null)
                  ) {
                    policyToEditIndex = this.policies.findIndex(
                      policy => policy.id === this.policyId || policy.name === this.policyName,
                    );

                    if (policyToEditIndex > 0) {
                      this.policyId = this.policies[policyToEditIndex].id;
                      $timeout(() => {
                        const dataListItem = $document[0].getElementById(
                          `${POLICIES_DATA_LIST_ITEM_ID}-${policyToEditIndex}`,
                        );

                        if (dataListItem !== null) {
                          dataListContainer.scrollTop = policyToEditIndex * dataListItem.offsetHeight;
                        }
                      }, 0);
                    }
                  } else {
                    policyToEditIndex = 0;
                  }

                  if (policyToEditIndex !== -1) {
                    updateActionsOnEntityChange(this.policies[policyToEditIndex]);
                  } else {
                    $translate('APP:COMMON:WARNINGS:ENTITY_NOT_EXISTS').then(translation => {
                      notificationService.warning(translation);
                    });
                  }
                }

                this.onDataLoaded();
              },
            )
            .catch(() => {
              this.onDataLoaded();

              $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
                notificationService.error(translation);
              });
            });
        }
      });
    };

    /* action handlers section --start */

    this.filterPolicies = criteria => {
      criteria = criteria.toLowerCase();

      return rule => {
        const { name, description, displayName } = rule;
        const nameValue = this.useDisplayNameForPolicy && displayName ? displayName : name;
        return nameValue?.toLowerCase().includes(criteria) || description?.toLowerCase().includes(criteria);
      };
    };

    this.onGoToTask = taskId => {
      goToTask(taskId);
    };

    this.downloadComplexAttributePolicy = taskId => {
      policiesService.downloadComplexAttributePolicy(taskId);
    };

    this.onSubmit = () => {
      onSubmitPolicy();
    };

    this.onCancel = () => {
      onCancelPolicy();
    };

    this.changeTriggerValue = value => {
      this.policy = {
        ...this.policy,
        complianceRuleCalc: {
          ...this.policy.complianceRuleCalc,
          actionOnTrigger: {
            ...(this.policy.complianceRuleCalc.actionOnTrigger || { type: 'Assign Attribute' }),
            value,
          },
        },
      };
    };

    this.changeTriggerType = type => {
      this.policy = {
        ...this.policy,
        complianceRuleCalc: {
          ...this.policy.complianceRuleCalc,
          actionOnTrigger: {
            type,
          },
        },
      };
    };

    this.onUserQuery = query => {
      this.policy = {
        ...this.policy,
        complianceRuleCalc: {
          ...this.policy.complianceRuleCalc,
          bigidQuery: query,
          maxFindings: null,
        },
      };
    };

    this.onEdit = policy => {
      if (this.policy.name !== policy.name) {
        if (this.isEditPermitted && (isFormDirty || isAppsDataHasChanged())) {
          onUserLeavesForm(
            () => {
              this.policyForm.$submitted = true;
              onSubmitPolicy(policy);
            },
            () => {
              updateActionsOnEntityChange(policy);
            },
          );
        } else {
          updateActionsOnEntityChange(policy);
        }
      }
    };

    this.onCreate = () => {
      this.isPolicyActionsEnabled && checkPolicyActions();
      this.isFrameworkComplianceEnabled && resetMappedControlsOnPolicyCreate();
      if (isFormDirty || isAppsDataHasChanged()) {
        onUserLeavesForm(
          () => {
            this.policyForm.$submitted = true;

            onSubmitPolicy({});
          },
          () => {
            onCreatePolicy();
          },
        );
      } else {
        onCreatePolicy();
      }
    };

    this.onDelete = async policy => {
      const { id } = policy;

      showDeleteACEntityDialog({
        ids: [id],
        entityNameSingular: 'policy',
        link: publicUrls.DELETE_POLICY_READ_MORE,
        entityType: EntityType.POLICY,
      })
        .then(shouldDelete => {
          if (shouldDelete) {
            policiesService.deletePolicy(id).then(() => {
              $translate('API:MESSAGE:COMMON_DELETE_SUCCESS').then(translation => {
                notificationService.success(translation);
              });

              if (this.formOnly) {
                this.onFormSubmitted({ isUpdated: true });
              } else {
                this.editModeOn = false;
                this.createModeOn = false;
                this.resetAppsActions();

                this.policy = {};

                fetchPolicies('top');
              }
            });
          }
        })
        .catch(() => {
          $translate('API:MESSAGE:COMMON_ERROR').then(translation => {
            notificationService.error(translation);
          });
        });
    };

    this.isSaveEnabled = () => {
      if (this.selectedApps.length !== 0 && this.selectedActions.length === 0) return false;
      if (this.selectedApps.length !== 0 && this.policy.type === 'privacy') return false;

      return this.policyForm?.$valid;
    };

    this.onTest = () => {
      onTestPolicy(this.policy);
    };

    this.onFormChanged = () => {
      isFormDirty = true;
    };

    this.selectInputChange = async searchString => {
      try {
        this.isDataLoading = true;
        if (!this.initialPoliciesOwners && this.policiesOwners) {
          this.initialPoliciesOwners = this.policiesOwners;
        }
        if (!searchString) {
          this.policiesOwners = this.initialPoliciesOwners;
        } else {
          const query = getUsersQuery({
            maxUsers: 20,
            searchString,
          });

          const {
            data: { users },
          } = await systemUsersService.getAllSystemUsersByQuery(query);
          this.policiesOwners = users.reduce(
            (names, user) => [...names, { id: names.length + 1, name: user.name }],
            [],
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        $scope.$applyAsync(() => {
          this.isDataLoading = false;
        });
      }
    };

    this.onSwitcherToggled = (isEnabled, ...rest) => {
      const policy = rest[0];

      const payload = angular.copy(policy);
      payload.is_enabled = isEnabled;

      if (typeof payload.selected != 'undefined') {
        delete payload.selected;
      }

      updatePolicy(payload.id, payload);
    };
  },
  bindings: {
    formOnly: '=',
    initialData: '<',
    formData: '<',
    policyId: '<',
    policyName: '<',
    filter: '<',
    onFormClosed: '&',
    onFormSubmitted: '&',
    onDataLoading: '&',
    onDataLoaded: '&',
  },
});
