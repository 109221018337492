import React from 'react';
import { BigidHeading5 } from '@bigid-ui/components';
import { useLocalTranslation } from '../../../translations';
import { Main, TitleWrapper, Content } from '../WidgetsStyles';
import { EmptyState } from '../EmptyState/EmptyState';
import { generateDataAid } from '@bigid-ui/utils';

interface DataSourceWidgetProps {
  data: any[];
  dataAid: string;
}

export const DataSourceWidget = ({ data, dataAid }: DataSourceWidgetProps) => {
  const { t } = useLocalTranslation('compliance.widgets.dataSource');

  return (
    <Main widgetHeigth="480px" data-aid={dataAid}>
      <TitleWrapper>
        <BigidHeading5>{t('title')}</BigidHeading5>
      </TitleWrapper>
      <Content>
        {data.length > 0 ? <></> : <EmptyState type="default" dataAid={generateDataAid(dataAid, ['emptyState'])} />}
      </Content>
    </Main>
  );
};
