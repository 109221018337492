import { httpService, QueryParamsAndDataObject } from '../../services/httpService';
import { BigidGridQueryComponents } from '@bigid-ui/grid';
import { queryService } from '../../services/queryService';

export const COMPLIANCE_FRAMEWORKS = 'compliance-frameworks';
export const COMPLIANCE_RULES = 'compliance-rules';
export const MAPPED_CONTROLS = 'mapped-controls';
export const RESET_MAPPED_CONTROLS = 'reset-mapped-controls';

export interface ControlsProps {
  id: string;
  _id: string;
  category: number | string;
  description: string;
  name: string;
  framework_name: string;
}

export interface FrameworkProps {
  id: string;
  _id: string;
  framework_name: string;
  number_of_controls?: number;
  enabled?: boolean;
  controls?: ControlsProps[];
  list?: ControlsProps[];
  selectedList?: string[];
  category?: number | string;
}

export interface IconsShcemaProps {
  [key: string]: any;
}

export interface FrameworkUpdatedProps {
  data: FrameworkProps[];
  amountOfControls: number;
}

export const fetchFrameworks = async (queryComponents: BigidGridQueryComponents) => {
  try {
    const gridConfigQuery = queryService.getGridConfigQuery(queryComponents);
    const {
      data: { data },
    } = await httpService.fetch(`${COMPLIANCE_FRAMEWORKS}?${gridConfigQuery}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const fetchFrameworksControls = async (name: string) => {
  try {
    const {
      data: { data },
    } = await httpService.fetch(`${COMPLIANCE_FRAMEWORKS}/${name}`);
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const updateFrameworksStatus = async (body: QueryParamsAndDataObject) => {
  try {
    await httpService.put(`${COMPLIANCE_FRAMEWORKS}/enabled-frameworks`, body);
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};

export const putMappedControls = async (policyFqdn: string, body: any) => {
  try {
    await httpService.put(`${COMPLIANCE_RULES}/${policyFqdn}/${MAPPED_CONTROLS}`, body);
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};

export const resetMappedControls = async (policyFqdn: string, frameworkName?: string) => {
  try {
    const {
      data: { data },
    } = await httpService.put(`${COMPLIANCE_RULES}/${policyFqdn}/${RESET_MAPPED_CONTROLS}`, {
      ...(frameworkName && { frameworkName }),
    });
    return data;
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
};
